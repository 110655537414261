import AudioListHeader from 'src/components/audio-list-header';
import AudioListItem from 'src/components/audio-list-item';
import { AlbumFieldsFragment, TrackSetFieldsFragment } from 'src/hooks/use-sdk';

type AudioListProps = {
  album: AlbumFieldsFragment;
  trackSets: TrackSetFieldsFragment[];
};

type AudioListSectionProps = {
  album: AlbumFieldsFragment;
  trackSet: TrackSetFieldsFragment;
};

const AudioListSection = ({ album, trackSet }: AudioListSectionProps): JSX.Element => {
  const { composerDisplayInfo, title, tracks } = trackSet;
  return (
    <div data-test="audio-list-section">
      {/* Sometimes a track set has only a title or just a composers name */}
      {(title || composerDisplayInfo) && <AudioListHeader composer={composerDisplayInfo} title={title} />}
      <ul className="border-t border-divider">
        {tracks.map((track) => (
          <li key={`audio-list-item-${track.id}`}>
            <AudioListItem album={album} track={track} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const AudioList = ({ trackSets, album }: AudioListProps): JSX.Element => {
  return (
    <div data-test="audio-list">
      {trackSets.map((trackSet, index) => {
        return <AudioListSection trackSet={trackSet} album={album} key={`audio-list-section-${index}`} />;
      })}
    </div>
  );
};

export default AudioList;
