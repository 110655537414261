import type { SVGProps } from 'react';
const SvgPictureInPicture = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.1 8.1V11H5V7.5a.5.5 0 0 1 .5-.5h13.1a.5.5 0 0 1 .5.5V9l.001 7.604a.497.497 0 0 1-.5.497H14V16h4V8.1h-5.45zm0 7.9v-2.8h5.8V16zM5 12.6a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPictureInPicture;
