import type { SVGProps } from 'react';
const SvgAirplay = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m14.85 15.045 1.108 1.25h2.249c.937 0 1.477-.532 1.477-1.469V7.532c0-.936-.54-1.47-1.477-1.47H5.8c-.91 0-1.484.534-1.484 1.47v7.294c0 .937.54 1.47 1.484 1.47h2.249l1.1-1.251H6.17c-.342 0-.533-.198-.533-.54V7.847c0-.335.191-.534.533-.534h11.669c.342 0 .533.199.533.534v6.658c0 .342-.191.54-.533.54zm1.19 3.534c.321 0 .444-.335.24-.567L12.293 13.5a.39.39 0 0 0-.588 0L7.72 18.012c-.205.232-.082.567.239.567z"
    />
  </svg>
);
export default SvgAirplay;
