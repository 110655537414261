import { RefObject, useEffect, useState } from 'react';
import { captureException } from 'src/utilities/exceptions';
import { noop } from 'src/utilities/noop';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ScreenOrientation {
    // redefine https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation
    lock?(orientation: OrientationType): Promise<void>;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Document {
    mozCancelFullScreen: () => void;
    webkitExitFullscreen: () => void;
    msExitFullscreen: () => void;
    webkitFullscreenElement: unknown;
    mozFullScreenElement: unknown;
    msFullscreenElement: unknown;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface HTMLElement {
    mozRequestFullScreen: () => void;
    webkitRequestFullscreen: () => void;
    webkitEnterFullscreen: () => void;
    msRequestFullscreen: () => void;
  }
}

const fullScreenElementIsActive: () => boolean = () =>
  // list of all fullscreen elements in different browsers
  !!(
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  );

/** cross-browser method for exiting the fullscreen mode */
export const exitFullScreen = () => {
  if (!fullScreenElementIsActive()) {
    return;
  }
  if (document.exitFullscreen) {
    void document.exitFullscreen().catch(captureException);
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
  // normally, when the fullscreen is exited, the screen lock is disabled by the browser
  // we'll reset it anyway, just to make sure we go back to the original state before fullscreen
  void screen.orientation?.unlock?.();
};

/** cross-browser method for entering the fullscreen mode */
const enterFullScreen = (element: HTMLElement) => {
  const videoElement = element.querySelector('video');
  if (element.requestFullscreen) {
    void element.requestFullscreen().catch(captureException);
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (videoElement?.webkitEnterFullscreen) {
    // on iPhone OS, only the video elements can go full screen
    // we will try to find the video element and request a full screen of it
    videoElement?.webkitEnterFullscreen();
  }
  // in case it's possible, try to lock the device in landscape mode until the full screen mode is turned off
  void screen.orientation?.lock?.('landscape-primary').catch(noop);
};

// list all fullscreen event names on different browsers
const fullscreenEvents = ['fullscreenchange', 'webkitfullscreenchange', 'msfullscreenchange', 'mozfullscreenchange'];

/**
 *
 * @hook
 * @param elRef the reference to the fullscreen element
 * @returns an object with 2 fields -
 *  `isFullscreen` boolean and `setFullscreen` method for activating the fullscreen mode
 */
const useFullscreenStatus = (elRef: RefObject<HTMLElement>) => {
  // track fullscreen DOM state locally
  const [isFullscreen, setIsFullscreen] = useState(false);

  const setFullscreen = (on: boolean) => {
    if (elRef.current == undefined) {
      return;
    }
    if (on) {
      enterFullScreen(elRef.current);
    } else {
      exitFullScreen();
    }
  };

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      // update the state if some element is set on full screen
      setIsFullscreen(fullScreenElementIsActive());
    };
    // we will register all cross-browser events instead of detecting specific browser
    for (const eventName of fullscreenEvents) {
      document.addEventListener(eventName, fullscreenChangeHandler);
    }

    return () => {
      // remove all full screen event listeners
      for (const eventName of fullscreenEvents) {
        document.removeEventListener(eventName, fullscreenChangeHandler);
      }
    };
  }, []);

  return { isFullscreen, setFullscreen };
};

export default useFullscreenStatus;
