type AudioListHeaderProps = {
  composer?: string;
  title?: string;
  performer?: string;
  orchester?: string;
  conductor?: string;
};

type SubTitleOptionalProps = {
  name: string | undefined;
};

const SubTitleOptional = ({ name }: SubTitleOptionalProps): JSX.Element => {
  if (name) {
    return <p className="dg-text-regular-4 text-white text-opacity-55">{name}</p>;
  }
  return <></>;
};

const AudioListHeader = ({ composer, title, orchester, conductor }: AudioListHeaderProps): JSX.Element => {
  return (
    <div className="py-3 text-center">
      <SubTitleOptional name={composer} />
      <p className="dg-text-regular-4">{title}</p>
      <SubTitleOptional name={orchester} />
      <SubTitleOptional name={conductor} />
    </div>
  );
};

export default AudioListHeader;
