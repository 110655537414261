import { RefObject, useState, useEffect, useCallback } from 'react';

/**
 * a specialized progress State hook, which allows reading and writing of
 * the current playback position, for performance reasons disconnected from the rest of the state
 * */
const useVideoProgress = (videoRef?: RefObject<HTMLVideoElement>) => {
  const [relativeProgress, setRelativeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  const onVideoTimeUpdate = useCallback((event: Event) => {
    const video = event.target as HTMLVideoElement;

    if (video) {
      setDuration(video.duration);
      setRelativeProgress(video.currentTime && video.duration ? Math.min(1, video.currentTime / video.duration) : 0);
      setCurrentTime(video.currentTime || 0);
      setRemainingTime(video.duration - video.currentTime || 0);
    }
  }, []);

  // observe every time the video tag actually changes,e.g. when video url is changed
  // so the listeners can be attached top the newly created video element
  const videoElement = videoRef?.current;

  useEffect(() => {
    videoElement?.addEventListener('timeupdate', onVideoTimeUpdate);
    return () => {
      videoElement?.removeEventListener('timeupdate', onVideoTimeUpdate);
    };
  }, [videoElement, onVideoTimeUpdate]);

  return { relativeProgress, currentTime, remainingTime, duration };
};

export default useVideoProgress;
