import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { SignupLink, ForgotPasswordLink } from 'src/components/auth-link';
import { TextButton } from 'src/components/buttons/text-button';
import ControlledInputPassword from 'src/components/forms/elements/controlled-input-password';
import ControlledInputText from 'src/components/forms/elements/controlled-input-text';
import { useEmailFromQuery } from 'src/hooks/use-email-from-query';
import { useShowNotification } from 'src/hooks/use-notification';
import useTranslate from 'src/hooks/use-translate';
import { useAuth } from 'src/state/auth';
import { trackEvent, useImpressionTracking } from 'src/tracking';
import { useTrackingContext } from 'src/tracking/tracking-context';
import { LinkAndButtonHandler } from 'src/types';
import { isEmail } from 'src/utilities/validator';

type LoginFormProps = {
  onForgotClick?: LinkAndButtonHandler;
  onLoginSuccess?: () => void;
  onChange?: () => void;
  displaySignup?: boolean; // display the form with a signup link
  displayForgotPassword?: boolean; // display the form with a forgot password link
  displayNotification?: boolean; // display a notification when the form is submitted
};

type FormValues = {
  email: string;
  password: string;
};

/**
 * A form displayed when user is trying to log in to their account,
 * the component can be displayed both on a static page or in a modal view.
 * In the modal case, you can override the internal links to Login and Signup
 *
 * @component
 *
 * @param onLoginClick - callback when user clicks on the Login link
 */

export default function LoginForm({
  onForgotClick,
  onLoginSuccess,
  onChange,
  displaySignup = true,
  displayForgotPassword = true,
  displayNotification = true,
}: LoginFormProps) {
  const t = useTranslate();
  const { control, handleSubmit, setError, setValue, formState } = useForm<FormValues>();
  const { login } = useAuth();

  // when ?email=foo@bar.baz parameter is present, prefill the email input
  // with that value
  const emailFromQuery = useEmailFromQuery();
  useEffect(() => {
    if (emailFromQuery) {
      setValue('email', emailFromQuery);
    }
  }, [emailFromQuery, setValue]);

  // show a notification if required
  const showNotification = useShowNotification();

  const trackingDisplayContext = useTrackingContext();

  // Track each time when the user sees the component
  const { impressionObserverRef } = useImpressionTracking({
    eventName: 'LoginDisplayed',
    method: 'Email',
    displayContext: trackingDisplayContext,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    try {
      // Try to login with the provided credentials
      await login({ username: email, password });

      if (displayNotification) {
        // display a success notification now
        showNotification({
          title: t('notification__logged_in_title'),
          text: t('notification__logged_in_text'),
          type: 'success',
          hideAfterSeconds: 15,
        });
      }
      // track log-in success
      trackEvent({
        eventName: 'LoginSuccess',
        method: 'Email',
        displayContext: trackingDisplayContext,
      });
      // execute after-log-in callback if provided
      onLoginSuccess?.();
    } catch {
      // currently the error returned by the API only specifies a generic error
      // so we’ll display a generic error message
      setError('email', { message: t('login__form_error_user_unknown') });
      setError('password', { message: t('login__form_error_user_unknown') });
      // track log-in error with a message provided by the server
      trackEvent({
        eventName: 'LoginError',
        method: 'Email',
        message: t('login__form_error_user_unknown'),
        displayContext: trackingDisplayContext,
      });
    }
  };

  // define the form submit handler including the validation tracking for the analytics
  const formSubmitHandler = handleSubmit(onSubmit, (errors) =>
    trackEvent({
      eventName: 'LoginError',
      method: 'Email',
      errors,
      displayContext: trackingDisplayContext,
    }),
  );

  return (
    <div data-test="login-form">
      <div className="mb-4">
        <h1 className="dg-text-medium-2 mb-8 text-center">{t('login__form_title')}</h1>
        {displaySignup && (
          <div className="dg-text-regular-3 text-center">
            <span className="opacity-75">{t('login__form_donthave')}</span>{' '}
            <SignupLink className="underline outline-none" trackingTextId="login__form_signup">
              {t('login__form_signup')}
            </SignupLink>
          </div>
        )}
      </div>
      <form
        onSubmit={formSubmitHandler}
        className="flex flex-col space-y-4"
        onChange={onChange}
        ref={impressionObserverRef}
      >
        <ControlledInputText
          label={t('login__form_email')}
          control={control}
          id="login__form_email"
          name="email"
          defaultValue=""
          rules={{
            required: t('login__form_error_email_required'),
            validate: { isEmail: (value) => isEmail(value) || t('login__form_error_email_wrong') },
          }}
          type="email"
          autoComplete="username"
        />
        <ControlledInputPassword
          label={t('login__form_password')}
          control={control}
          id="login__form_password"
          name="password"
          defaultValue=""
          rules={{
            required: t('login__form_error_password_required'),
          }}
          type="password"
          autoComplete="current-password" // this helps the browser to store and autofill the password
        />

        {displayForgotPassword && (
          <div>
            <ForgotPasswordLink
              className="dg-text-regular-6 underline"
              onClick={onForgotClick}
              dataTest="button-user-forgot-password"
              trackingTextId="login__form_forgot_password"
            >
              {t('login__form_forgot_password')}
            </ForgotPasswordLink>
          </div>
        )}

        <div>
          <TextButton type="submit" data-test="button-user-login-submit" loading={formState.isSubmitting} fullWidth>
            {t('login__form_button')}
          </TextButton>
        </div>
      </form>
    </div>
  );
}
