import { RefObject, useState } from 'react';
import clsx from 'clsx';
import { useIdleTimer } from 'react-idle-timer';
import { RemoveScroll } from 'react-remove-scroll';
import { PictureType } from 'generated/graphql';
import ImageSquare from 'src/components/common/image-square';
import { useSitePlayerContext } from 'src/components/site-player';
import {
  SitePlayerChaptersList,
  SitePlayerChaptersToggleMobile,
  SitePlayerControls,
  SitePlayerControlsOptions,
} from 'src/components/site-player-controls';
import SitePlayerError from 'src/components/site-player-error';
import Spinner from 'src/components/spinner';
import useFullscreenStatus from 'src/hooks/use-fullscreen';
import { useScreenOrientation } from 'src/hooks/use-screen-orientation';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { ErrorTypes } from 'src/utilities/hls-js-extracted';
import { getPictureByType } from 'src/utilities/image-helpers';

// when to hide the controls when the user is not interacting with the player
const hideControlsAfterSeconds = 3;

/**
 * the site player when playing over the whole page.
 * It uses the global playback state and video context to access the video element
 *
 * @component
 */
const SitePlayerMaxi = ({ playerRef }: { playerRef: RefObject<HTMLElement> }) => {
  // a reference to the main video tag element
  const { playbackError, isLoading } = useVideoContext();
  const hasAuthError =
    playbackError?.code === 401 || // missing auth token, triggered by failed fetch in hls.js
    playbackError?.code === 403 || // missing or invalid auth token, triggered by failed fetch in hls.js
    playbackError?.errorType === ErrorTypes.KEY_SYSTEM_ERROR; // missing or invalid auth token, but triggered via the native HLS player
  // the site player state
  const { trackListVisible } = useSitePlayerContext();

  // full screen control hook
  const { isFullscreen, setFullscreen } = useFullscreenStatus(playerRef);

  // player state - queue, styles, etc.
  const { currentMedia, isAudio, currentStream } = usePlayback();
  const streamHasFinished = currentMedia?.__typename === 'LiveConcert' && currentStream === undefined;

  // moving of the mouse or any other interaction makes the controls visible
  // and controls will be hidden after some time again
  const screenOrientation = useScreenOrientation();
  const isPortrait = screenOrientation === 'portrait';
  const [userInteracting, setUserInteracting] = useState(true);
  useIdleTimer({
    timeout: hideControlsAfterSeconds * 1000,
    onIdle: () => {
      const isVideo = !isAudio;
      // hide the controls on videos,
      // but only in a landscape mode and when the track list is NOT visible
      if (isVideo && !isPortrait && !trackListVisible) {
        setUserInteracting(false);
      }
    },
    onActive: () => setUserInteracting(true),
    debounce: 500,
  });

  const handleMini = () => {
    // exit fullscreen when minifying the player
    setFullscreen(false);
  };

  // first cover Image
  const coverImage =
    currentMedia && 'pictures' in currentMedia && getPictureByType(currentMedia.pictures, PictureType.Cover)?.url;

  return (
    // the player container has pointer events disabled,
    // so that the video can be interacted with in the background
    <div
      className={clsx(
        'pointer-events-none absolute inset-0 flex h-full items-center justify-center',
        // hide the mouse cursor when hiding controls
        userInteracting ? 'cursor-auto' : 'cursor-none',
      )}
    >
      {/* On vertical mobile, the video takes up only the upper part of the screen, on wider screens it fills the entire view */}
      <div className="relative flex size-full items-center self-start md:h-full md:items-center portrait:h-1/2 md:portrait:h-full">
        {isAudio ? (
          /* Album Cover Container */
          <div className="pointer-events-none flex h-2/3 w-full items-end justify-center bg-deepBlueC4/40 md:items-start">
            <div
              className="relative size-[184px] overflow-hidden rounded-lg md:size-[276px] 2xl:size-[408px]"
              data-test="site-player-audio-cover-image"
            >
              {coverImage && <ImageSquare width={408} height={408} src={coverImage} alt={currentMedia?.title} />}
              {isLoading && (
                <Spinner className="absolute left-1/2 top-1/2 z-20 -ml-6 -mt-6 size-12 md:-ml-8 md:-mt-8 md:size-16" />
              )}
            </div>
          </div>
        ) : (
          isLoading && (
            /* Loading Indicator Container */
            <div className="pointer-events-none absolute inset-0 flex items-end justify-center bg-greyG3/40 pb-20 md:items-center md:pb-0">
              <Spinner className="size-12 md:size-16" />
            </div>
          )
        )}
      </div>
      {/* All Player Controls Container */}
      <RemoveScroll enabled={true}>
        {/* Display controls only when user is interacting with the screen */}
        <div
          className={clsx(
            'absolute inset-0 transform-gpu transition-opacity duration-200',
            userInteracting ? 'opacity-100' : 'opacity-0',
          )}
        >
          {/* Player Options Container - Fullscreen, Subtitles, etc. */}
          <div
            className={clsx(
              'pointer-events-auto absolute inset-x-0 top-0 z-50 px-4 pb-14 pt-8 md:px-8',
              // configure the background gradient for the playback options
              'bg-gradient-to-t from-[rgba(21,31,49,0)] via-deepBlueC4 to-deepBlueC4',
            )}
          >
            <SitePlayerControlsOptions
              isFullscreen={isFullscreen}
              onFullScreenOn={() => setFullscreen(true)}
              onFullScreenOff={() => setFullscreen(false)}
              onMini={() => handleMini()}
            />
          </div>
          {/* Playback Controls Container - Pause, Skip, Timeline, Favorite, Subtitles, etc. */}
          {/* The playback controls are positioned below the video on mobile but on larger screens they overlay the video */}
          <div
            className={clsx(
              'pointer-events-auto absolute bottom-0 left-0 z-40 flex w-full items-start pt-8 md:items-end landscape:h-2/3 md:landscape:h-2/5',
              // configure the background gradient for the playback controls
              'bg-gradient-to-b from-[rgba(21,31,49,0)] via-deepBlueC4 to-deepBlueC4',
            )}
          >
            <div className="w-full pb-20 md:pb-10 lg:pb-16 2xl:pb-20">
              <SitePlayerControls />
            </div>
          </div>
        </div>
        {/** a special tracks or works toggle on mobile screens */}
        <SitePlayerChaptersToggleMobile />
        {/* The list of tracks or works, it has to be above all elements on z axis */}
        <SitePlayerChaptersList />

        {/* Player Error Container */}
        {playbackError && (
          <SitePlayerError errorDisplayType={hasAuthError ? 'AuthError' : 'GenericError'} error={playbackError} />
        )}
        {streamHasFinished && <SitePlayerError errorDisplayType="StreamFinished" />}
      </RemoveScroll>
    </div>
  );
};

export default SitePlayerMaxi;
