import type { SVGProps } from 'react';
const SvgWorklist = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6.313 6.063C5.574 6.063 5 6.664 5 7.375c0 .738.574 1.313 1.313 1.313.71 0 1.312-.575 1.312-1.313a1.33 1.33 0 0 0-1.312-1.312m0 4.375c-.739 0-1.313.601-1.313 1.312 0 .738.574 1.313 1.313 1.313.71 0 1.312-.575 1.312-1.313a1.33 1.33 0 0 0-1.312-1.312m0 4.374c-.739 0-1.313.602-1.313 1.313 0 .738.574 1.313 1.313 1.313.71 0 1.312-.575 1.312-1.313a1.33 1.33 0 0 0-1.312-1.312m12.25.438h-8.75a.45.45 0 0 0-.438.438v.874c0 .247.191.438.438.438h8.75a.45.45 0 0 0 .437-.437v-.875a.47.47 0 0 0-.437-.438m0-8.75h-8.75a.45.45 0 0 0-.438.438v.875c0 .246.191.437.438.437h8.75A.45.45 0 0 0 19 7.813v-.875a.47.47 0 0 0-.437-.438m0 4.375h-8.75a.45.45 0 0 0-.438.438v.874c0 .247.191.438.438.438h8.75a.45.45 0 0 0 .437-.437v-.876a.47.47 0 0 0-.437-.437"
    />
  </svg>
);
export default SvgWorklist;
