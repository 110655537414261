import { useCallback, useEffect } from 'react';
import useSWR from 'swr';
import { Client, RecordPlaybackProgressMutation } from 'generated/graphql';
import { env } from 'src/config';
import useInterval from 'src/hooks/use-interval';
import { usePlayerUrl } from 'src/hooks/use-player-url';
import useSdk from 'src/hooks/use-sdk';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { PlaybackContentType } from 'src/types';
import { getClientDevice } from 'src/utilities/device-info';

// the interval in which the playback progress is logged
const defaultLoggingIntervalSeconds = 10;

// types of player media and might request the playback progress
type StreamPositionMediaTypes = PlaybackContentType | 'Trailer' | 'Track' | 'PerformanceWork';

/**
 * A hook that retrieves and stores the played position of the audio/video stream via the backend API.
 * @param contentId - a content ID of the current media
 */
export const useStreamPositionStore = (
  media:
    | {
        id: string;
        __typename: StreamPositionMediaTypes;
      }
    | undefined,
): [number | undefined, (streamTime: number) => Promise<RecordPlaybackProgressMutation | undefined>] => {
  const sdk = useSdk();
  const contentId = media?.id;
  // try to fetch the latest stored position of the given media
  const tryToRetrieveStreamPosition =
    // the call requires a content ID
    contentId &&
    // and only the following content types have stored position that we will attempt to restore
    (media?.__typename === 'PerformanceWork' || media?.__typename === 'Video');
  // if we don't have to fetch, we will pass `null` and the call be will be ignored by SWR
  const { data } = useSWR(tryToRetrieveStreamPosition ? `progress/${contentId}` : null, () =>
    sdk.playbackProgress({ id: contentId || '' }),
  );
  // get the current client device type, e.g. `WebDesktop` or `WebOther`
  const clientDevice = getClientDevice();
  // get the stored position if it exists
  const storedProgress = data?.playbackProgressByContentId?.mark;
  // store the position on the backend
  const setStoredProgress = useCallback(
    async (streamTime: number) => {
      // only the whole seconds are stored, no fractions like 0.5 seconds
      const mark = Math.floor(streamTime);
      // Only some types of media have to be logged
      const isLoggable =
        media?.__typename === 'LiveConcert' ||
        media?.__typename === 'PerformanceWork' ||
        media?.__typename === 'Video' ||
        media?.__typename === 'Track';
      // we can store positions only if we have a content id and the mark position is greater than 0
      if (!contentId || !isLoggable || mark < 1) {
        return;
      }
      // get the Unix timestamp in seconds
      const clientTimestamp = Math.floor(Date.now() / 1000);
      // log the progress to the backend
      return await sdk.recordPlaybackProgress({
        input: {
          contentId,
          mark,
          clientTimestamp,
          client: Client.Web,
          clientDevice,
          clientVersion: env.NEXT_VERSION_INFO || 'unknown',
          heartbeatRate: defaultLoggingIntervalSeconds,
        },
      });
    },
    [clientDevice, contentId, media?.__typename, sdk],
  );

  return [storedProgress, setStoredProgress];
};

// the progress will be restored on stream initialization only if there is still enough content left to play.
const minimumRestorePosition = 0.9;

/**
 * A hook that logs playback progress on the video in a given logIntervalSeconds interval
 * and automatically restores the last played position in the video stream
 * it will handle the case when deep linking into a plyer position,
 * which will take precedence over the stored position
 */
export const useVideoProgressMemory = () => {
  // a reference to the main video tag element
  const { seek, videoRef } = useVideoContext();
  // get the current player URL state
  const { cueTime } = usePlayerUrl();

  // get the currently loaded media
  const { currentMedia, currentActiveWork, currentActiveTrack, liveConcertState, playbackQueue } = usePlayback();
  // retrieve and store the last-played position of the stream of the media
  const playingMediaItem = currentActiveWork || currentActiveTrack || currentMedia;
  const [storedProgress, setStoredProgress] = useStreamPositionStore(playingMediaItem);

  // we are not logging the progress if the live stream is preparing
  const streamShouldBeLogged = liveConcertState?.status !== 'pre-live';

  // the callback that will be called regularly when the video is playing
  const playLogger = useCallback(() => {
    const streamTime = videoRef.current?.currentTime;

    // if the player is playing, log the progress
    if (!videoRef.current?.paused && streamTime && streamShouldBeLogged) {
      void setStoredProgress(streamTime);
      return;
    }
  }, [videoRef, setStoredProgress, streamShouldBeLogged]);

  // store the last-played position regularly
  useInterval(playLogger, defaultLoggingIntervalSeconds * 1000);

  // restore the last-played position when the video is loaded
  const restoreStreamProgress = useCallback(
    (storedProgress?: number) => {
      // get the duration of the currently loaded stream
      const duration = videoRef.current?.duration || 0;
      // fast-forward to the stored position, but only if the video still has some content left
      if (storedProgress && storedProgress < duration * minimumRestorePosition) {
        seek(storedProgress);
      }
    },
    [seek, videoRef],
  );
  // check if we are allowed to restore the last-played position
  const allowToRestoreProgress = playbackQueue.restorePreviousPlayback;

  const checkStoredProgress = useCallback(() => {
    // try to get the deep-linked timestamp from the url param
    // if there's a deep-link time set in the URL, use it first
    if (cueTime !== undefined) {
      restoreStreamProgress(cueTime);
      return;
    }
    // otherwise, if we have a stored position on a video, and we are allowed to restore, tell the player about it
    if (storedProgress && allowToRestoreProgress) {
      restoreStreamProgress(storedProgress);
    }
  }, [cueTime, storedProgress, allowToRestoreProgress, restoreStreamProgress]);

  // monitor the changes in the starting time cue, which are triggered by the user via the player URL
  useEffect(() => {
    // first check if the video is seekable
    const loadedVideoDuration = videoRef.current?.duration;
    // if the video is already loaded, check if we have a starting position from the URL
    if (cueTime !== undefined && loadedVideoDuration && cueTime < loadedVideoDuration) {
      seek(cueTime);
    }
  }, [seek, cueTime, videoRef]);

  // observe every time the video tag actually changes,e.g. when video url is changed
  // so the listeners can be attached top the newly created video element
  const videoElement = videoRef.current;

  // setup the stream progress listeners on every stream initialization
  useEffect(() => {
    // check if we need to seek to some predefined position once the player is ready
    videoElement?.addEventListener('loadedmetadata', checkStoredProgress);
    // store position on seeking immediately
    videoElement?.addEventListener('seeked', playLogger);
    return () => {
      videoElement?.removeEventListener('loadedmetadata', checkStoredProgress);
      videoElement?.removeEventListener('seeked', playLogger);
    };
  }, [checkStoredProgress, playLogger, videoElement]);
};
