import { RefObject, useState, useEffect } from 'react';
import { noop } from 'src/utilities/noop';

/**
 * RemotePlayback button is available on some clients,
 * this hook detects if it should be displayed and offers the click handler
 * that will display the built-in remote playback devices menu
 *
 * @param videoRef a reference to the video HTML element
 * @param onChange called when the video changes a remote playback state
 */
const useRemotePlayback = ({
  videoRef,
  onChange,
}: {
  videoRef?: RefObject<HTMLVideoElement>;
  onChange?: () => void;
}) => {
  const [remotePlaybackAvailable, setRemotePlaybackAvailable] = useState<boolean>(false);
  const [remotePlaybackActive, setRemotePlaybackActive] = useState<boolean>(
    videoRef?.current?.remote?.state === 'connected',
  );

  // display a remote playback selector, usually a list of audio and video devices on the current Wi-FI network
  const showRemotePlaybackPicker = () => {
    // the prompt() method occasionally throws a recoverable `notAllowedError` error in Safari
    // they are not critical, so we can safely ignore them
    void videoRef?.current?.remote.prompt().catch(noop);
  };

  useEffect(() => {
    const video = videoRef?.current;
    // if Remote Playback is not available, don't bother with the event listeners
    if (!video?.remote) {
      return;
    }

    // observe when the remote streaming devices become available
    void video.remote.watchAvailability((available: boolean) => setRemotePlaybackAvailable(available));

    // check if it's an Apple browser, they have a bug in Remote Playback implementation
    // that's triggering disconnect listeners right after connect
    const unreliableDisconnect = 'webkitShowPlaybackTargetPicker' in video;

    const handleConnection = () => {
      const currentConnectionState = videoRef?.current?.remote.state;
      setRemotePlaybackActive(currentConnectionState === 'connected');
      onChange?.();
    };

    // notify if we are connected to the remote device
    // keep in mind the Apple Airplay bug - most likely only the recently connected status will be up-to-date
    video.remote.addEventListener('connect', handleConnection);
    if (!unreliableDisconnect) {
      video.remote.addEventListener('disconnect', handleConnection);
    }

    return () => {
      video.remote.removeEventListener('connect', handleConnection);
      if (!unreliableDisconnect) {
        video.remote.removeEventListener('disconnect', handleConnection);
      }
      void video?.remote.cancelWatchAvailability();
    };
  }, [onChange, videoRef]);

  return { remotePlaybackAvailable, showRemotePlaybackPicker, remotePlaybackActive };
};

export default useRemotePlayback;
