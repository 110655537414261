import { Fragment, HTMLProps, useEffect } from 'react';
import useSWR from 'swr';
import { PictureType, InfoModalQuery, PartnerType } from 'generated/graphql';
import { Badge } from 'src/components/common/badge';
import ImageSquare from 'src/components/common/image-square';
import { TransientErrorMessage } from 'src/components/errors/transient-messages';
import { LiveConcertBadge } from 'src/components/live-concert/live-concert-badge';
import Modal from 'src/components/modal';
import { ContainerSpinner } from 'src/components/spinner';
import useIntl from 'src/hooks/use-intl';
import { useInfoModal } from 'src/hooks/use-modal';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { formatCredits } from 'src/utilities/credits';
import { secondsToMinutes } from 'src/utilities/date-helpers';
import { captureException } from 'src/utilities/exceptions';
import { getPictureByType } from 'src/utilities/image-helpers';
import { secondsToTimecode } from 'src/utilities/seconds-timecode';

type InfoModalNode = Extract<
  InfoModalQuery['node'],
  { __typename: 'Album' | 'Track' | 'Video' | 'LiveConcert' | 'VodConcert' }
>;

/**
 * Track specific info modal header component that is used by InfoModalHeader.
 * Track header should include:
 * - Album cover
 * - Track number
 * - Composer
 * - Track title
 */
function InfoModalHeaderTrack({ node }: { node: Extract<InfoModalNode, { __typename: 'Track' }> }) {
  const t = useTranslate();
  const { album, composerDisplayInfo, title, trackNumber } = node;
  const image = getPictureByType(album.pictures, PictureType.Cover)?.url;

  return (
    <>
      <div className="mx-auto size-32 overflow-hidden rounded">
        <ImageSquare height={128} width={128} src={image} alt={album.title} />
      </div>
      <div className="mt-3 space-y-3">
        <div>
          <div className="dg-text-regular-6 opacity-55">{t('metaInfoTrackNumber', [trackNumber])}</div>
          <h1 className="dg-text-medium-3">{title}</h1>
        </div>
        {composerDisplayInfo ? (
          <dl className="dg-text-regular-6">
            <div>
              <dt>{t('credits__composer')}</dt>
              <dd className="text-greyG2">{composerDisplayInfo}</dd>
            </div>
          </dl>
        ) : null}
      </div>
    </>
  );
}

/**
 * Album specific info modal header component that is used by InfoModalHeader.
 * Album header should include:
 * - Album cover
 * - Album title
 */
function InfoModalHeaderAlbum({ node }: { node: Extract<InfoModalNode, { __typename: 'Album' }> }) {
  const { pictures, title } = node;
  const image = getPictureByType(pictures, PictureType.Cover)?.url;

  return (
    <>
      <div className="mx-auto size-32 overflow-hidden rounded">
        <ImageSquare height={128} width={128} src={image} alt={title} />
      </div>
      <div className="mt-3">
        <h1 className="dg-text-medium-3">{title}</h1>
      </div>
    </>
  );
}

/**
 * Video specific info modal header component that is used by InfoModalHeader.
 * Video header should include:
 * - Title
 * - Subtitle
 * - Partner type (Venue)
 */
function InfoModalHeaderVideo({
  node,
}: {
  node: Extract<InfoModalNode, { __typename: 'LiveConcert' | 'Video' | 'VodConcert' }>;
}) {
  const { listFormat } = useIntl();
  const { __typename, pictures, subtitle, title, typeDisplayName } = node;
  const partners =
    'partners' in node ? node.partners.filter((partner) => partner.type === PartnerType.Venue) : undefined;
  const image = getPictureByType(pictures, PictureType.TeaserSquare)?.url;
  const subline = partners && partners.length > 0 ? listFormat(partners.map(({ name }) => name)) : undefined;
  const isLiveConcert = __typename === 'LiveConcert';

  return (
    <>
      <div className="mx-auto size-32 overflow-hidden rounded">
        <ImageSquare height={128} width={128} src={image} alt={title} />
      </div>
      <div className="mt-3">
        <div className="mb-3">
          {isLiveConcert ? <LiveConcertBadge liveConcert={node} small /> : <Badge small>{typeDisplayName}</Badge>}
        </div>
        <h1 className="dg-text-medium-3 mb-1">{title}</h1>
        {subtitle && <h2 className="dg-text-regular-3">{subtitle}</h2>}
        {subline && <h3 className="dg-text-regular-3 mt-3 text-greyG2">{subline}</h3>}
      </div>
    </>
  );
}

/**
 * Displays header information about the given node in the info modal.
 */
function InfoModalHeader({ node, ...rest }: HTMLProps<HTMLElement> & { node: InfoModalNode }) {
  const { __typename } = node;

  return (
    <header {...rest}>
      {__typename === 'Album' && <InfoModalHeaderAlbum node={node} />}
      {__typename === 'Track' && <InfoModalHeaderTrack node={node} />}
      {(__typename === 'LiveConcert' || __typename === 'Video' || __typename === 'VodConcert') && (
        <InfoModalHeaderVideo node={node} />
      )}
    </header>
  );
}

/**
 * Displays credits for a given node in the info modal.
 */
function InfoModalCredits({ node, ...rest }: HTMLProps<HTMLDivElement> & { node: InfoModalNode }) {
  const t = useTranslate();
  const { listFormat } = useIntl();
  const { __typename } = node;

  /**
   * Track credits should include:
   * - Ensemble (Group)
   * - Conductor
   */
  if (__typename === 'Track') {
    const { groupDisplayInfo, conductorDisplayInfo } = node;
    // Don’t show anything if neither group nor conductor info is available
    if (!groupDisplayInfo && !conductorDisplayInfo) return null;

    return (
      <div {...rest}>
        <dl className="dg-text-regular-6">
          {groupDisplayInfo && (
            <div>
              <dt>{t('credits__ensemble')}</dt>
              <dd className="text-greyG2">{groupDisplayInfo}</dd>
            </div>
          )}
          {conductorDisplayInfo && (
            <div>
              <dt>{t('credits__conductor')}</dt>
              <dd className="text-greyG2">{conductorDisplayInfo}</dd>
            </div>
          )}
        </dl>
      </div>
    );
  }

  /**
   * Concerts should include:
   * - Long form concert introduction where available
   */
  if ('longFormConcertIntroduction' in node && node.longFormConcertIntroduction) {
    return (
      <div {...rest}>
        <p className="dg-text-regular-6 text-greyG4">
          {node.longFormConcertIntroduction.split('\n').map((line, index) => (
            <Fragment key={index}>
              {line}
              <br />
            </Fragment>
          ))}
        </p>
      </div>
    );
  }

  const creditsList = formatCredits({
    conductors: 'conductors' in node ? node.conductors?.edges : undefined,
    groups: node.groups,
    soloists: 'soloists' in node ? node.soloists?.edges : undefined,
  });

  /**
   * Album and Video (and concerts without long form introduction) credits should include:
   * - Soloist(s) with role
   * - Ensemble(s) (Group)
   * - Conductor(s)
   */
  if (creditsList.length > 0) {
    return (
      <div {...rest}>
        <dl className="dg-text-regular-6 space-y-3">
          {creditsList.map(({ titleTranslationKey, values }) => (
            <div key={titleTranslationKey}>
              <dt>{t(titleTranslationKey, { count: values.length })}</dt>
              <dd className="text-greyG2">{listFormat(values)}</dd>
            </div>
          ))}
        </dl>
      </div>
    );
  }

  // Don’t show anything if no credits are available
  return null;
}

/**
 * Displays meta information about a node in the info modal.
 */
function InfoModalMetaInfo({ node, ...rest }: HTMLProps<HTMLDivElement> & { node: InfoModalNode }) {
  const t = useTranslate();
  const { dateFormat } = useIntl();
  /**
   * Array of grouped meta info strings. The strings are grouped by their relevance and will be displayed with a
   * divider between them.
   * e.g. [
   *   ['Total duration: 01:23'],
   *   ['Release date: 01.01.2021', 'Recorded date: 01.01.2020']
   * ]
   */
  const metaInfo: string[][] = [];

  // Helper to add a new string to the given group index
  function addMetaInfo(groupIndex: number, value: string) {
    metaInfo[groupIndex] = [...(metaInfo[groupIndex] || []), value];
  }

  /**
   * Album metadata should include:
   * - Total album duration
   * - Release date
   * - Album UPCs (SD, HD, Dolby Atmos)
   * - Phonographic copyright
   * - Copyright
   */
  if (node.__typename === 'Album') {
    addMetaInfo(
      0,
      t('metaInfoLabel__album_duration', [t('video__duration_in_minutes', [secondsToMinutes(node.totalDuration)])]),
    );
    if (node.releaseDate)
      addMetaInfo(1, t('metaInfoLabel__album_releaseDate', [dateFormat(new Date(node.releaseDate), 'short')]));
    if (node.upc) addMetaInfo(2, t('metaInfoLabel__album_upc', [node.upc]));
    if (node.atmosUpc) addMetaInfo(2, t('metaInfoLabel__album_atmosUpc', [node.atmosUpc]));
    addMetaInfo(3, t('metaInfoLabel__copyright', [node.albumCopyright]));
    addMetaInfo(3, t('metaInfoLabel__phonographicCopyright', [node.phonographicCopyright]));
  }

  /**
   * Track metadata should include:
   * - Track duration
   * - Recording date
   * - First release
   * - Recording location
   * - Recording setting
   * - Producer(s)
   * - Engineer(s)
   * - ISRC
   * - Phonographic copyright
   * - Copyright
   */
  if (node.__typename === 'Track') {
    addMetaInfo(0, t('metaInfoLabel__track_duration', [secondsToTimecode(node.duration)]));
    if (node.firstReleaseYear) addMetaInfo(1, t('metaInfoLabel__track_firstRelease', [node.firstReleaseYear]));
    if (node.recordingSessionEnd)
      addMetaInfo(
        1,
        t('metaInfoLabel__track_recordingDate', [dateFormat(new Date(node.recordingSessionEnd), 'monthYear')]),
      );
    if (node.recordingLocation) addMetaInfo(2, t('metaInfoLabel__track_recordingLocation', [node.recordingLocation]));
    if (node.recordingSetting) addMetaInfo(2, t('metaInfoLabel__track_recordingSettings', [node.recordingSetting]));
    if (node.producerDisplayInfo) addMetaInfo(2, t('metaInfoLabel__track_producer', [node.producerDisplayInfo]));
    if (node.engineerDisplayInfo) addMetaInfo(2, t('metaInfoLabel__track_engineer', [node.engineerDisplayInfo]));
    if (node.isrc) addMetaInfo(3, t('metaInfoLabel__track_isrc', [node.isrc]));
    addMetaInfo(4, t('metaInfoLabel__copyright', [node.album.copyright]));
    addMetaInfo(4, t('metaInfoLabel__phonographicCopyright', [node.album.phonographicCopyright]));
  }

  /**
   * Video metadata should include:
   * - Video director
   * - Photographer
   * - Phonographic copyright
   * - Copyright
   * - Production date
   * - Available until date
   * - Total duration
   * - Concert ISRC
   */
  if (node.__typename === 'LiveConcert' || node.__typename === 'Video' || node.__typename === 'VodConcert') {
    if (node.videoDirector) addMetaInfo(0, t('metaInfoLabel__video_director', [node.videoDirector]));
    if (node.photographer) addMetaInfo(0, t('metaInfoLabel__video_photographer', [node.photographer]));
    if (node.production) addMetaInfo(1, node.production);
    if (node.copyright) addMetaInfo(1, node.copyright);
    if (node.__typename !== 'LiveConcert' && node.productionDate)
      addMetaInfo(2, t('metaInfoLabel__video_dateOfConcert', [dateFormat(new Date(node.productionDate), 'short')]));
    if (node.__typename !== 'LiveConcert' && node.takedownDate)
      addMetaInfo(2, t('video__available_until_day', [dateFormat(new Date(node.takedownDate), 'short')]));
    addMetaInfo(
      2,
      t('metaInfoLabel__video_concertDuration', [
        t('video__duration_in_minutes', [
          secondsToMinutes(node.__typename === 'Video' ? node.duration : node.totalDuration),
        ]),
      ]),
    );
    if (node.isrc) addMetaInfo(3, t('concert_isrc_label', [node.isrc]));
    if (node.__typename === 'VodConcert' && node.courtesyOf) {
      addMetaInfo(4, node.courtesyOf);
    }
  }

  return (
    <div {...rest}>
      <div className="space-y-3">
        {metaInfo.filter(Boolean).map((group, groupIndex) => (
          <div key={groupIndex} className="dg-text-regular-6 text-greyG2">
            {group.map((value, valueIndex) => (
              <div key={valueIndex}>{value}</div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function InfoModalError({ nodeId }: { nodeId?: string }) {
  const t = useTranslate();
  // Log the error to Sentry
  useEffect(() => {
    captureException(new Error(`Info modal error: Node ID: ${nodeId}`));
  }, [nodeId]);

  // Display a transient error message
  return (
    <div className="flex items-center justify-center">
      <TransientErrorMessage title={t('error_page_404__title')} message={t('error_page_404__message')} log={nodeId} />
    </div>
  );
}

function InfoModalContent() {
  const sdk = useSdk();
  const { data: value } = useInfoModal();
  const nodeId = value?.id;
  // Conditionally load the node data based on the node id
  const { data, isLoading } = useSWR(
    nodeId ? `infoModal/${nodeId}` : undefined,
    () => (nodeId ? sdk.infoModal({ nodeId }) : undefined),
    {
      onError: (error) => {
        console.info('Error fetching info modal data', error);
      },
    },
  );
  // Because the `infoModal` query does a generic Node lookup, we need to filter out non-supported node types
  const node: InfoModalNode | undefined =
    data?.node?.__typename === 'Album' ||
    data?.node?.__typename === 'Track' ||
    data?.node?.__typename === 'Video' ||
    data?.node?.__typename === 'VodConcert' ||
    data?.node?.__typename === 'LiveConcert'
      ? data.node
      : undefined;

  return (
    <div className="divide-y divide-divider">
      {node && (
        <Fragment>
          <InfoModalHeader node={node} className="py-6 first:pt-0 last:pb-0" />
          <InfoModalCredits node={node} className="py-6 first:pt-0 last:pb-0" />
          <InfoModalMetaInfo node={node} className="py-6 first:pt-0 last:pb-0" />
        </Fragment>
      )}
      {/* If the data is still loading, display a spinner */}
      {isLoading && !node && <ContainerSpinner />}
      {/* If the data is loaded and no node was found, display an error message */}
      {!isLoading && !node && <InfoModalError nodeId={nodeId} />}
    </div>
  );
}

/**
 * Info modal displaying additional information about a certain node type
 * Available node types are: Album, Track, Video, LiveConcert, VodConcert
 */
export default function InfoModal() {
  const { isOpen, close } = useInfoModal();
  return (
    <Modal open={isOpen} onClose={close} dataTest="info-modal">
      {/* we have following choices:
      - if we want the modal to appear faster, we should pre-render.
        Run the component immediately if there's modal state data, no matter if `isOpen` is `false`
      - if we prefer to reduce the client CPU usage, we should allow render only if `isOpen` is `true`
       */}
      <Modal.Content>
        <InfoModalContent />
      </Modal.Content>
    </Modal>
  );
}
