import { useState, useRef, useCallback, MouseEvent, useEffect } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import Timeline from 'src/components/timeline';
import useVideoProgress from 'src/hooks/use-video-progress';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { secondsToTimecode } from 'src/utilities/seconds-timecode';

/**
 * The main progress slider of the video player
 * @component
 */
export const SitePlayerProgress = (): JSX.Element => {
  const { currentActiveWork } = usePlayback();
  // the global video object
  const { videoRef, seekRelative } = useVideoContext();
  // get the hook to the playback progress
  const { relativeProgress, currentTime, duration, remainingTime } = useVideoProgress(videoRef);

  // a reference to the video tag
  const video = videoRef.current;
  // a reference to the progress element container
  const containerRef = useRef<HTMLDivElement>(null);
  // keep the state of progress handle
  const [progressHandleState, setProgressHandleState] = useState({ dragging: false, x: 0 });

  const onProgressSeek = useCallback(
    (xPos: number) => {
      const container = containerRef.current;
      if (video && container) {
        // find out where in the progress indicator user has clicked
        const relativeSeekPosition = xPos / container.offsetWidth;
        // tell the player to go to the same relative position
        seekRelative(relativeSeekPosition);
      }
      // set local handle position immediately, don't wait for video to update its current time
      // this will allow us to have snappier interactions
      // also reset dragging state, after the seek was completed
      setProgressHandleState({ dragging: false, x: xPos });
    },
    [video, containerRef, seekRelative],
  );
  // what happens when user starts dragging the progress handle
  const draggingStartHandler = useCallback((_event: DraggableEvent, data: DraggableData) => {
    setProgressHandleState({ dragging: true, x: data.x });
  }, []);
  // what happens when user is dragging the progress handle
  const draggingHandler = useCallback((_event: DraggableEvent, data: DraggableData) => {
    setProgressHandleState({ dragging: true, x: data.x });
  }, []);
  // what happens when user ends dragging the progress handle
  const draggingStopHandler = useCallback(
    (_event: DraggableEvent, data: DraggableData) => {
      // seek in the video when user stops dragging
      onProgressSeek(data.x);
    },
    [onProgressSeek],
  );
  // what happens when user clicks on a particular part of the progress bar
  const clickToSeekHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      // seek in the video when user clicks somewhere in the progress indicator
      const { x } = containerRef.current?.getBoundingClientRect() || { x: 0 };
      onProgressSeek(event.clientX - x);
    },
    [onProgressSeek],
  );

  // update the visible position of the progress handle
  useEffect(() => {
    if (progressHandleState.dragging) {
      // if user is dragging the handle, the position will be updated with event handlers above
    } else if (videoRef.current?.seeking) {
      // if video is in the seeking mode, do nothing, keep the handle where it is
      // this way, if e.g. user just finished dragging teh handle, it will stay at the future position
      // and won't quickly jump back to the yet unchanged current time
    } else {
      // in all other cases, normal operation
      // display the current playback progress of the video
      setProgressHandleState({
        dragging: false,
        x: (containerRef.current?.offsetWidth || 0) * relativeProgress,
      });
    }
  }, [progressHandleState.dragging, progressHandleState.x, relativeProgress, videoRef]);

  // calculate how to mask the progress bar to display the progress, in pixels
  const progressInsetPosition = (containerRef.current?.offsetWidth || 0) - progressHandleState.x;

  // a reference to the draggable container, required to avoid the React 19 errors `findDOMNode is deprecated`
  // @todo [react-draggable@>4.4.6] remove this once the react-draggable library is updated to be compatible with React 19
  const draggableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div data-test="site-player-progress" className="relative select-none no-highlight" ref={containerRef}>
      <div
        className="relative h-2"
        role="slider"
        aria-valuenow={Math.round(currentTime)}
        aria-valuemin={0}
        aria-valuemax={Math.round(duration)}
        onClick={clickToSeekHandler}
      >
        {/* we are overlaying two timelines, to create the playback progress indication */}
        <div className="w-full cursor-pointer">
          {/* the first timeline is interactive, slightly greyed-out by default */}
          <Timeline cuePoints={currentActiveWork?.cuePoints} duration={duration} />
        </div>
        {/* the second one is only to display the current playback position, layered on top and masked accordingly */}
        <div
          className="pointer-events-none absolute inset-x-0 top-0 w-full transform-gpu"
          style={{ clipPath: `inset(-4px ${progressInsetPosition}px -4px 0px)` }}
        >
          <Timeline highlighted={true} cuePoints={currentActiveWork?.cuePoints} duration={duration} />
        </div>
        {/*the draggable handler */}
        <Draggable
          axis="x"
          bounds="parent"
          position={{ x: progressHandleState.x, y: 0 }}
          onStart={draggingStartHandler}
          onStop={draggingStopHandler}
          onDrag={draggingHandler}
          nodeRef={draggableContainerRef}
        >
          {/* the scrubber hit area is larger than the visible element */}
          <div
            className="relative -top-4 -mx-2 flex h-7 w-4 transform-gpu cursor-pointer items-center justify-center bg-white/0"
            ref={draggableContainerRef}
          >
            <div className="h-4 w-1.5 rounded-sm bg-white drop-shadow-[0_1px_2px_rgba(0,0,0,0.16)]"></div>
          </div>
        </Draggable>
      </div>
      <div className="dg-text-regular-4 mt-1.5 flex w-full select-none justify-between opacity-70">
        <div>{secondsToTimecode(currentTime)}</div>
        <div>-{secondsToTimecode(remainingTime)}</div>
      </div>
    </div>
  );
};

/**
 * The progress indicator of the mini video player
 * @component
 */
export const MiniPlayerProgress = () => {
  // the global video object
  const { videoRef } = useVideoContext();
  // get the hook to the playback progress
  const { relativeProgress } = useVideoProgress(videoRef);
  return (
    <div className="relative h-0.5 w-full">
      <div
        className="absolute left-0 top-0 h-0.5 w-full origin-top-left transform-gpu rounded-lg bg-brandYellowC1"
        style={{ transform: `scale3d(${relativeProgress}, 1, 1)` }}
      ></div>
    </div>
  );
};
