import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { useGeoBlocking } from 'src/hooks/use-geo-blocking';
import usePermissions from 'src/hooks/use-permissions';
import { usePlayerUrl } from 'src/hooks/use-player-url';
import useSdk from 'src/hooks/use-sdk';
import { usePlayback } from 'src/state/playback';
import { PlayerContentQueryData, TrailerVideo } from 'src/types';
import { noop } from 'src/utilities/noop';
import { getTrailerMedia } from 'src/utilities/queue-helpers';

/**
 * A hook that loads content for the player
 * it is fetching the content from the API automatically when the player URL is activated on a detail page
 * e.g. /video/vod_concert_APNM8GRFDPHMASJKBSP0?trackId=performance_work_A1IN4PJFE9MM2RJ3CLBMUSJBBSS0&player=true
 */
const usePlayerContentLoader = (): {
  playerData: PlayerContentQueryData | TrailerVideo | undefined;
  trackId: string | undefined;
} => {
  const { playerUrlEntered, trailerActive, trackId } = usePlayerUrl();
  const { query } = useRouter();
  // try to read the node id if we are on a player page
  const contentId = playerUrlEntered ? String(query.pid) : undefined;
  const sdk = useSdk();
  // load the player content when the player page is activated
  const { data } = useSWR(contentId ? `playerContent/${contentId}` : null, () =>
    sdk.playerContent({ contentId: contentId || '' }),
  );
  // extract the media from the edge node structure
  const playerData: PlayerContentQueryData | undefined =
    data?.node?.__typename === 'Video' ||
    data?.node?.__typename === 'Album' ||
    data?.node?.__typename === 'VodConcert' ||
    data?.node?.__typename === 'LiveConcert'
      ? data.node
      : undefined;

  // if we should load a trailer instead of main content
  if (playerData && trailerActive) {
    // provide a trailer video data object
    return { playerData: getTrailerMedia(playerData), trackId };
  }

  return { playerData, trackId };
};

/**
 * A hook that prepares content for the player, checks the geo blocking and the user permissions,
 * should be used only in the site player
 */
export const usePlayerContent = (): {
  playerData: PlayerContentQueryData | TrailerVideo | undefined;
  isContentGeoBlocked: boolean;
} => {
  // the player will fetch the necessary content automatically when we enter the player page
  const { playerData, trackId } = usePlayerContentLoader();

  const { resetPlayerUrl } = usePlayerUrl();
  // we'll have to check if the content isn't blocked in the users country
  const { isGeoBlockedNode } = useGeoBlocking();
  // check if the content is blocked in the users country (if the content is not a trailer because it’s a special case)
  const isContentGeoBlocked = playerData && playerData.__typename !== 'Trailer' ? isGeoBlockedNode(playerData) : false;
  // check if user is allowed to play the stream
  const { openRequiredModalsToPlayMedia, checkMediaRequirements } = usePermissions();
  const mediaRequirements = playerData ? checkMediaRequirements(playerData) : null;
  // prepare the playback queue operations
  const { replaceQueue, isCurrentMedia } = usePlayback();

  // the player will be visible when the queue is not empty

  // init the player, when the data when is loaded
  useEffect(() => {
    // if the player data is already loaded and initialized, we do nothing
    if (!playerData || isCurrentMedia(playerData)) {
      return;
    }
    // the permissions are still loading, we'll have to wait
    if (!mediaRequirements || mediaRequirements === 'isLoading') {
      return;
    }
    // if the player data shows that the content is blocked,
    // we don't want to initialize the player
    if (isContentGeoBlocked) {
      return;
    }
    // the user seems to be allowed to play the content, proceed initializing the player queue
    if (mediaRequirements === 'allGood') {
      replaceQueue(playerData, trackId);
    } else {
      // if we got this far, it means the user has probably opened a deep-link to a specific track
      // otherwise they would have gotten the modals already before they got to the player page
      // first, get off the player page, otherwise the player will try to continuously to re-initialize
      resetPlayerUrl()
        .then(() => {
          // open the required modals to allow the user to signup, login, etc
          openRequiredModalsToPlayMedia(playerData);
        })
        .catch(noop);
      return;
    }
  }, [
    isContentGeoBlocked,
    playerData,
    trackId,
    replaceQueue,
    isCurrentMedia,
    resetPlayerUrl,
    openRequiredModalsToPlayMedia,
    mediaRequirements,
  ]);

  return { playerData, isContentGeoBlocked };
};
