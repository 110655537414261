import { useEffect } from 'react';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { cloudinaryLoaderSquare } from 'src/utilities/image-sdk';
import { getAbsoluteUrl } from 'src/utilities/url-helpers';

// the amount of time to skip when the user clicks the skip-time buttons
const defaultSkipTime = 30;

/**
 * Media Session API is a web standard that allows web developers to let users know what's playing in their web app and control media playback from the system's media notification or media control UI.
 * https://developers.google.com/web/updates/2017/02/media-session
 * e.g. the artwork of the current media will be displayed in the lock screen or OS widgets that allow playlback control
 * the media buttons, such as skip to next or previous, will be handled by the browser as well
 */
export function useMediaSessionApi() {
  const { currentActiveTrack, currentActiveWork, currentMedia, skipToNext, skipToPrevious } = usePlayback();

  const { play, pause, seek, videoRef } = useVideoContext();

  // update the media session when the current media changes
  useEffect(() => {
    const supportsMediaSession = navigator && 'mediaSession' in navigator;
    if (!supportsMediaSession) {
      return;
    }

    if (!currentMedia) {
      navigator.mediaSession.metadata = null;
    }

    // the metadata will be different depending on the type of current media
    let title = '';
    let artist = '';
    let album = '';
    // a default image to display if we don't have a picture
    const defaultImageSource = getAbsoluteUrl('/images/og-image.png');
    // use the picture from the current media if we have it
    const imageSource = currentMedia && 'pictures' in currentMedia ? currentMedia.pictures[0]?.url : defaultImageSource;

    // use the track info if we have it
    if (currentActiveTrack?.title) {
      title = currentActiveTrack.title;
      artist = currentActiveTrack?.artistAndGroupDisplayInfo || '';
      album = currentMedia?.title || '';
      // use the current work info if we have it
    } else if (currentActiveWork?.work.title) {
      title = currentActiveWork.work.title;
      // use the composer name if we have it, like on the player page
      artist = currentActiveWork?.work.composers?.map((composer) => composer.name).join(', ') || '';
      album = currentMedia?.title || '';
      // use the video or a live concert info if we have it
    } else if (currentMedia?.__typename === 'Video' || currentMedia?.__typename === 'LiveConcert') {
      title = currentMedia.title;
      artist =
        [...(currentMedia.soloists?.edges ?? []), ...(currentMedia.conductors?.edges ?? [])]
          .map((artist) => artist.node.name)
          .join(', ') || '';
    }

    navigator.mediaSession.metadata = new MediaMetadata({
      title,
      artist,
      album,
      // if the image
      artwork: imageSource
        ? [
            {
              src: cloudinaryLoaderSquare({ src: imageSource, width: 640, format: 'jpg' }),
              sizes: '640x640',
              type: 'image/jpeg',
            },
            {
              src: cloudinaryLoaderSquare({ src: imageSource, width: 300, format: 'jpg' }),
              sizes: '300x300',
              type: 'image/jpeg',
            },
            {
              src: cloudinaryLoaderSquare({ src: imageSource, width: 64, format: 'jpg' }),
              sizes: '64x64',
              type: 'image/jpeg',
            },
          ]
        : [],
    });
    // set the action handlers for the media buttons
    navigator.mediaSession.setActionHandler('play', play);
    navigator.mediaSession.setActionHandler('pause', pause);
    navigator.mediaSession.setActionHandler('previoustrack', skipToPrevious);
    navigator.mediaSession.setActionHandler('nexttrack', skipToNext);
    navigator.mediaSession.setActionHandler('seekbackward', (event) => {
      const skipTime = event.seekOffset || defaultSkipTime;
      seek((currentTime: number) => currentTime - skipTime);
    });
    navigator.mediaSession.setActionHandler('seekforward', (event) => {
      const skipTime = event.seekOffset || defaultSkipTime;
      seek((currentTime: number) => currentTime + skipTime);
    });
    navigator.mediaSession.setActionHandler('seekto', (event) => {
      // fastSeek allows us to seek to a specific time
      // potentially faster than the seek method but sacrifices accuracy
      if (event.fastSeek && videoRef.current && 'fastSeek' in videoRef.current && event.seekTime) {
        videoRef.current.fastSeek(event.seekTime);
        return;
      }
      if (event.seekTime) {
        seek(event.seekTime);
      }
    });
    // note that the play/pause button is handled by the browser itself

    return () => {
      if (supportsMediaSession) {
        // reset the media session when the player is closed
        navigator.mediaSession.metadata = null;
      }
    };
  }, [currentActiveTrack, currentActiveWork, currentMedia, pause, play, seek, skipToNext, skipToPrevious, videoRef]);
}
