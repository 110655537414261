import type { SVGProps } from 'react';
const SvgBackward = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m4.3 12.434 5.25 4.375c.575.465 1.45.082 1.45-.684v-8.75c0-.738-.875-1.12-1.45-.656L4.3 11.094a.883.883 0 0 0 0 1.34m7 0 5.25 4.375c.575.465 1.45.082 1.45-.684v-8.75c0-.738-.875-1.12-1.45-.656l-5.25 4.375a.883.883 0 0 0 0 1.34"
    />
  </svg>
);
export default SvgBackward;
