import { create } from 'zustand';

type ConfirmStateProperties = {
  /**
   * The confirmation message to display to the user
   * @example 'Are you sure you want to delete this item?'
   * @default ''
   */
  prompt: string;
  /**
   * An additional message to display to the user
   * @example 'This action can't be undone.'
   * @default undefined
   */
  message?: string;
  /**
   * Whether the confirmation dialog is open
   * @default false
   */
  open: boolean;
  /**
   * The action to perform when the user confirms the dialog
   * @default undefined
   */
  confirm: ((value: unknown) => void) | undefined;
  /**
   * The action to perform when the user cancels the dialog
   * @default undefined
   */
  cancel: (() => void) | undefined;
  /**
   * The action to be performed can't be undone
   * @default undefined
   */
  destructive?: boolean | undefined;
};

type UseConfirmHookOptions = Pick<ConfirmStateProperties, 'prompt' | 'message' | 'destructive'>;

type ConfirmState = {
  /**
   * The properties of the confirm state
   **/
  properties: ConfirmStateProperties;
  /**
   * Close the confirm dialog but don't reset the state yet
   */
  closeConfirm: () => void;
  /**
   * Reset the confirm state to the default values
   */
  reset: () => void;
  /**
   Produce a promise that resolves to a boolean value. Similar to how the native `window.confirm`
    * @example
  * ```tsx
  * const confirm = useConfirmState((state) => state.initConfirm);
  * const handleClick = async () => {
  *   const isConfirmed = await confirm({prompt: 'Are you sure you want to delete this item?'});
  *   if (isConfirmed) {
  *     // delete something
  *   }
  * }
  * ```
   */
  initConfirm: (options: UseConfirmHookOptions) => Promise<boolean>;
};

const defaultStateProperties: ConfirmStateProperties = {
  prompt: '',
  message: undefined,
  open: false,
  confirm: undefined,
  cancel: undefined,
  destructive: false,
};

/**
 * The confirm state atom that stores the confirmation dialog state in Zustand.
 * This is used by the `useConfirm` hook and the `Confirm` component to display
 * a custom confirmation dialog to the user.
 */
export const useConfirmState = create<ConfirmState>((set, get) => ({
  properties: defaultStateProperties,
  closeConfirm: () => set((state) => ({ properties: { ...state.properties, open: false } })),
  replace: (newConfirm: ConfirmStateProperties) => set({ properties: newConfirm }),
  reset: () => set({ properties: defaultStateProperties }),
  /**
   * Open the confirmation dialog
   * @param prompt The prompt to display in the confirm dialog.
   * @param options.destructive flag particularly dangerous actions that cannot be undone.
   */
  initConfirm: ({ prompt, message, destructive }: UseConfirmHookOptions) => {
    // Create a new promise that sets the confirm state and passes the resolve
    // and reject functions to the confirm state so they can be called from the
    // confirm dialog.
    const confirmationPromise = new Promise((resolve, reject) =>
      set({
        ...get(),
        properties: {
          prompt,
          message,
          open: true,
          confirm: resolve,
          cancel: reject,
          destructive,
        },
      }),
    );

    // Return the promise so the caller can wait for the result of the confirm
    // dialog (resolve or reject).
    return confirmationPromise.then(
      // If the user confirmed, close the confirm dialog and return true (confirmed)
      () => {
        get().closeConfirm();
        return true;
      },
      // If the user cancelled, close the confirm dialog and return false (cancelled)
      () => {
        get().closeConfirm();
        return false;
      },
    );
  },
}));
