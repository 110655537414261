import { ReactNode, Ref, forwardRef, InputHTMLAttributes } from 'react';
import CheckmarkIcon from '@stageplus/icons/react/checkmark';
import clsx from 'clsx';

type InputCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string | ReactNode;
  helperText?: string;
  error?: boolean;
};

/**
 * A basic checkbox field with a label and optional error text
 *
 * @example
 * ```tsx
 * const [checked, setChecked] = useState<boolean>(false);
 * <InputCheckbox label="I agree" name="tos" defaultChecked={false} checked={checked} onChange={() => setChecked(true)} />
 * ```
 */
export default forwardRef<HTMLInputElement, InputCheckboxProps>(function CheckboxField(
  componentProps: InputCheckboxProps,
  forwardedRef: Ref<HTMLInputElement>,
) {
  const { name, checked, label, helperText, error = false, ...inputProps } = componentProps;
  /** Unique id for the checkbox */
  const id = `checkbox-${name || ''}`;

  return (
    <div className="flex items-start justify-start space-x-2">
      <div className="box-content flex size-4 shrink-0 items-center justify-center rounded-sm border border-divider bg-formBlueC5 shadow-sm">
        <input
          className="absolute size-3 border-none opacity-0"
          ref={forwardedRef}
          name={name}
          checked={checked}
          type="checkbox"
          {...inputProps}
          id={id}
        />
        {checked && <CheckmarkIcon className="text-brandYellowC1" aria-hidden />}
      </div>
      <div className="relative">
        <label
          className="dg-text-regular-6 relative -top-1 inline-block cursor-pointer select-none text-white text-opacity-70 2xl:leading-snug"
          htmlFor={id}
        >
          {label}
        </label>
        {helperText && (
          <div
            className={clsx('dg-text-regular-8', error ? 'text-errorOrangeC6' : 'text-white')}
            data-test={error ? 'input-checkbox-error' : 'input-checkbox-helper'}
          >
            {helperText}
          </div>
        )}
      </div>
    </div>
  );
});
