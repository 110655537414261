import type { SVGProps } from 'react';
const SvgAttentionTwoTone = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M3.52 3.51A12 12 0 0 0 12 24v-1.5a10.5 10.5 0 1 1 0-21V0a12 12 0 0 0-8.48 3.51"
      opacity={0.4}
    />
    <path fill="currentColor" d="M12 0v1.5a10.5 10.5 0 0 1 0 21V24a12 12 0 0 0 0-24" opacity={0.8} />
    <path fill="currentColor" d="M11 6h2v8h-2zm0 12h2v-2h-2z" opacity={0.8} />
  </svg>
);
export default SvgAttentionTwoTone;
