import CalendarIcon from '@stageplus/icons/react/calendar';
import useSWR from 'swr';
import { PictureType } from 'generated/graphql';
import ImageSquare from 'src/components/common/image-square';
import { LiveConcertBadge } from 'src/components/live-concert/live-concert-badge';
import Modal from 'src/components/modal';
import Time from 'src/components/time';
import useIntl from 'src/hooks/use-intl';
import { useReminderModal } from 'src/hooks/use-modal';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { getPictureByType } from 'src/utilities/image-helpers';
import { generateLiveConcertIcal, getUpcomingLiveConcertDates } from 'src/utilities/live-concert-helpers';

function ReminderModalContent() {
  const t = useTranslate();
  const { dateFormat } = useIntl();

  const { data } = useReminderModal();

  const sdk = useSdk();
  const reminderModalRequest = useSWR(data ? `reminderModal/${data.id}` : null, () =>
    sdk.reminderModal({ liveConcertId: data?.id || '' }),
  );

  const liveConcert = reminderModalRequest.data?.liveConcert;

  if (!liveConcert) return null;

  const { title, seriesTitle, startTime, pictures, subtitle } = liveConcert;
  const image = getPictureByType(pictures, PictureType.TeaserSquare)?.url;
  const liveConcertDates = getUpcomingLiveConcertDates(liveConcert);

  return (
    <div className="divide-y divide-divider">
      <div className="dg-text-regular-3 -mt-2 pb-6 md:pb-8 2xl:-mt-6 2xl:pb-10">
        <h2 className="dg-text-medium-3 mb-4 text-center 2xl:pb-5">{t('reminder__info_header')}</h2>
        <div className="mx-auto mb-3 size-32 overflow-hidden rounded">
          <ImageSquare height={128} width={128} src={image} alt={title} />
        </div>
        <LiveConcertBadge liveConcert={liveConcert} small />
        <h1 className="dg-text-medium-3 mt-4">{title}</h1>
        <h2 className="mt-2">{subtitle}</h2>
        {seriesTitle && <h3 className="mt-2 text-greyG2">{seriesTitle}</h3>}
        {startTime && (
          <p className="mt-2 text-greyG2" suppressHydrationWarning>
            {t('metaInfoLabel__video_dateOfConcert', [dateFormat(new Date(startTime), 'mediumTime')])}
          </p>
        )}
      </div>
      {liveConcertDates.length > 0 && (
        <div className="dg-text-regular-3">
          <div className="mb-8 mt-6">{t('modal_reminder__intro_description')}</div>
          <ul
            className="mb-4 divide-y divide-mainBgBlueC2 overflow-hidden rounded-lg"
            data-test="live-concert-reminders"
          >
            {liveConcertDates.map((liveConcertDate) => (
              <li
                key={liveConcertDate.startTime}
                className="dg-text-regular-6 flex justify-between bg-deepBlueC4/50 px-4 py-3"
              >
                <Time value={liveConcertDate.startTime} style="mediumTime" />
                <button
                  className="flex items-start justify-center whitespace-nowrap text-left"
                  onClick={() =>
                    liveConcert &&
                    generateLiveConcertIcal({
                      ...liveConcert,
                      startTime: liveConcertDate.startTime,
                      endTime: liveConcertDate.endTime,
                    })
                  }
                >
                  <CalendarIcon className="-mt-0.5 mr-0.5 lg:mt-0" />
                  <span className="dg-text-regular-6 mt-0">{t('modal_reminder__add_to_calendar')}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

/**
 * A Reminder Modal lists the basic info of a LiveConcert alongside
 * an option to be reminded of premieres or reruns.
 */
export default function ReminderModal() {
  const { isOpen, close } = useReminderModal();

  return (
    <Modal open={isOpen} onClose={close} dataTest="reminder-modal">
      <Modal.Content>
        <ReminderModalContent />
      </Modal.Content>
    </Modal>
  );
}
