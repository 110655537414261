import { useState, useEffect, ChangeEvent, useRef } from 'react';
import VolumeIcon from '@stageplus/icons/react/volume';
import clsx from 'clsx';
import { ButtonIconRound } from 'src/components/buttons/icon-button';
import styles from 'src/components/controls-volume.module.css';
import useClickOutside from 'src/hooks/use-click-outside';
import useLocalStorage from 'src/hooks/use-local-storage';
import useTranslate from 'src/hooks/use-translate';
import { useVideoContext } from 'src/state/video';
import { isTouchDevice } from 'src/utilities/capabilities';

/**
 * A volume controller panel
 * @param display - true if the panel should be displayed
 * @param onClose - a callback when user clicks outside of the volume controller area, usually to hide the control
 * @component
 */
const ControlsVolumePanel = ({ display, onClose }: { display: boolean; onClose: () => void }) => {
  const t = useTranslate();
  // hooks into the video tag
  const { videoRef } = useVideoContext();

  const initialVolume = 1;

  // we will persist volume if it needs to be restored
  const [storedVolume, setStoredVolume] = useLocalStorage<number>(
    'stored-volume',
    videoRef?.current?.volume || initialVolume,
  );
  // we will keep the current volume in the non persisted state, in case the user wants to mute it
  const [currentVolume, setCurrentVolume] = useState(storedVolume);

  // get the video element, re-render the component when element changes e.g. when the video URL has changed
  const videoElement = videoRef.current;

  // restore the volume when the new element is created, so every track and video starts with the same volume
  useEffect(() => {
    if (videoElement) {
      videoElement.volume = currentVolume;
    }
  }, [currentVolume, videoElement]);

  // update the video volume when the user changes it
  const volumeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseFloat(event.target.value);
    setCurrentVolume(newValue);
    setStoredVolume(newValue);
  };

  // toggle the volume on and off, with persistence of the previous volume
  const volumeToggleHandler = () => {
    if (currentVolume > 0) {
      // remember the sound level we had before muting
      setStoredVolume(currentVolume);
      // set the video volume
      setCurrentVolume(0);
    } else {
      setCurrentVolume(storedVolume || initialVolume);
    }
  };

  // invoke a callback on a click outside
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside({ ref: containerRef, callback: onClose, isActive: display });

  return (
    <div
      className={clsx(
        'h-8 items-center justify-between rounded-full bg-white text-deepBlueC4',
        display ? 'flex' : 'hidden',
      )}
      ref={containerRef}
      data-test="controls-volume"
    >
      <ButtonIconRound
        title={t(currentVolume > 0 ? 'player__control_volume_mute' : 'player__control_volume_unmute')}
        icon={<VolumeIcon className={currentVolume ? 'text-deepBlueC4' : 'text-greyG2'} aria-hidden />}
        onClick={volumeToggleHandler}
      />
      <input
        type="range"
        aria-label={t('player__control_volume')}
        id="volume-control"
        value={currentVolume}
        max={1}
        step={0.01}
        onChange={volumeChangeHandler}
        className={clsx('mr-2 w-28', styles.range)}
        style={{ backgroundSize: `${currentVolume * 100}% 100%` }}
      />
    </div>
  );
};

/**
 * Volume control button and panel
 * On touch devises it renders only a placeholder
 * as the users prefer dedicated volume control buttons there
 * @component
 */
const ControlsVolume = () => {
  const t = useTranslate();
  const [displayVolumePanel, setDisplayVolumePanel] = useState(false);

  const useNativeVolumeControls = isTouchDevice();

  // in case we don't render the controls,
  // leave a placeholder so the player controls layout is not affected
  if (useNativeVolumeControls) {
    return <div className="relative size-8"></div>;
  }

  return (
    <div className="relative z-20">
      <ButtonIconRound
        title={t('player__button_volume')}
        icon={<VolumeIcon aria-hidden />}
        onClick={() => setDisplayVolumePanel(true)}
        dataTest={'display-volume-button'}
      />
      <div className="absolute left-0 top-0">
        <ControlsVolumePanel display={displayVolumePanel} onClose={() => setDisplayVolumePanel(false)} />
      </div>
    </div>
  );
};

export default ControlsVolume;
