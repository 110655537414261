import type { SVGProps } from 'react';
const SvgPrevious = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M8 17.668c0 .188.125.322.3.322h1.2c.15 0 .3-.134.3-.322v-4.683l4.875 4.817c.525.455 1.325.08 1.325-.669V6.856c0-.722-.8-1.097-1.325-.642L9.8 11.058V6.321c0-.16-.15-.321-.3-.321H8.3c-.175 0-.3.16-.3.321z"
    />
  </svg>
);
export default SvgPrevious;
