'use client';
import Confirm from 'src/components/confirm';
import ModalManager from 'src/components/modal/modal-manager';
import ModalAuth from 'src/components/modal-auth';
import NotificationManager from 'src/components/notification-manager';
import RenderClientOnly from 'src/components/render-client-only';
import SitePlayer from 'src/components/site-player';

/**
 * Group all the main client-side components into one wrapper
 * that can be loaded dynamically in the main layout.
 * This way we could save both on the bundle size and initialization time
 * @component
 */
export default function AppClientComponents() {
  return (
    <RenderClientOnly>
      <SitePlayer />
      <ModalAuth />
      <ModalManager />
      <Confirm />
      <NotificationManager />
    </RenderClientOnly>
  );
}
