import type { SVGProps } from 'react';
const SvgBackward30Sec = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path fill="none" stroke="currentColor" strokeWidth={1.3} d="M12.005 5.88A6.125 6.125 0 1 1 8.473 7" />
    <g fill="currentColor">
      <path d="M12.803 4.396v3.275c.015.453-.512.657-.833.336L10.333 6.37c-.19-.19-.19-.483.014-.687l1.638-1.638a.475.475 0 0 1 .818.351" />
      <path
        stroke="#fff"
        strokeWidth={0.05}
        d="M9.057 13.554a.38.38 0 0 0-.49-.24.366.366 0 0 0-.238.48 1.2 1.2 0 0 0 1.15.83h.905c.84 0 1.542-.691 1.542-1.542s-.703-1.543-1.542-1.543h-.182l1.415-1.296a.37.37 0 0 0 .096-.418.37.37 0 0 0-.347-.25H8.687a.385.385 0 0 0-.382.382c0 .215.167.382.382.382h1.689L8.96 11.646a.37.37 0 0 0-.096.418c.048.145.193.24.358.24h1.16a.79.79 0 0 1 .78.778.78.78 0 0 1-.78.779H9.49a.444.444 0 0 1-.434-.307zm5.15-3.968a1.8 1.8 0 0 0-1.811 1.81v1.418c0 .996.803 1.81 1.81 1.81.997 0 1.811-.814 1.811-1.81v-1.428c0-.997-.815-1.8-1.81-1.8zm1.046 3.228c0 .578-.48 1.047-1.046 1.047a1.047 1.047 0 0 1-1.047-1.047v-1.428c0-.567.47-1.047 1.047-1.047.566 0 1.046.48 1.046 1.047z"
      />
    </g>
  </svg>
);
export default SvgBackward30Sec;
