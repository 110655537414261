import type { SVGProps } from 'react';
const SvgFullscreenExit = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.984 16.453a.43.43 0 0 0 0 .602l.711.71a.43.43 0 0 0 .602 0l2.734-2.734.848.903c.41.437 1.121.136 1.121-.465v-3.063c0-.355-.3-.656-.656-.656H8.28c-.601 0-.902.71-.465 1.121l.903.848zm12.004-9.379a.43.43 0 0 0 0-.601l-.71-.711a.43.43 0 0 0-.602 0l-2.707 2.707-.875-.875A.628.628 0 0 0 12 8.03v3.063c0 .383.273.656.629.656h3.062c.602 0 .903-.684.465-1.094l-.875-.875z"
    />
  </svg>
);
export default SvgFullscreenExit;
