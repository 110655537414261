import { TranslationKeyCommon } from 'src/types';

type ArtistWithRole = {
  node: { name: string };
  role: { displayName: string };
};

type GroupWithType = {
  name: string;
  typeDisplayName: string;
};

type RawCredits = {
  conductors?: ArtistWithRole[];
  groups?: GroupWithType[];
  soloists?: ArtistWithRole[];
};

type FormattedCredits = {
  titleTranslationKey: TranslationKeyCommon;
  values: string[];
}[];

/**
 * Accepts a sorted list of conductors, groups and, soloists and reshapes it into a normalized format.
 *
 * The credits are displayed in the following order:
 * - soloists (artists with ArtistRoleFilter.Soloists) with their role displayed between parentheses
 * - ensembles (groups)
 * - conductors (artists with ArtistRoleFilter.Conductors)
 */
export function formatCredits({ conductors, groups, soloists }: RawCredits): FormattedCredits {
  const creditsList: FormattedCredits = [];

  // Add soloists in the format "Artist Name (Role)" e.g. "Miloš Karadaglić (Guitar)"
  if (soloists && soloists.length > 0) {
    creditsList.push({
      titleTranslationKey: 'soloist',
      values: soloists.map((soloist) => `${soloist.node.name} (${soloist.role.displayName})`),
    });
  }

  // Add groups in the format "Group Name" e.g. "London Symphony Orchestra"
  if (groups && groups.length > 0) {
    creditsList.push({
      titleTranslationKey: 'ensemble',
      values: groups.map((group) => group.name),
    });
  }

  // Add conductors in the format "Conductor Name" e.g. "Gustavo Dudamel"
  if (conductors && conductors.length > 0) {
    creditsList.push({
      titleTranslationKey: 'conductor',
      values: conductors.map((conductor) => conductor.node.name),
    });
  }

  return creditsList;
}
