import { ReactNode } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import InputCheckbox from 'src/components/common/input-checkbox';

type ControlledInputCheckboxProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string | ReactNode;
};

/**
 * Extends the `InputCheckbox` component to add the ability to control the value of the input through the `useController` hook.
 * @see https://react-hook-form.com/api/usecontroller/
 *
 * @example
 * ```tsx
 * const { control } = useForm<FormValues>();
 * <ControlledInputCheckbox label="I agree" name="tos" control={control} rules={{ required: "Please agree to the ToS" }} />
 * ```
 */
export default function ControlledInputCheckbox<T extends FieldValues>({
  label,
  control,
  name,
  defaultValue,
  rules,
  ...inputProps
}: ControlledInputCheckboxProps<T>) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, error },
  } = useController({
    control,
    name,
    defaultValue,
    rules,
  });

  return (
    <InputCheckbox
      label={label}
      error={invalid}
      helperText={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      checked={value}
      name={name}
      ref={ref}
      {...inputProps}
    />
  );
}
