import type { SVGProps } from 'react';
const SvgVolume = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M10.004 6.719 7.57 9.125H4.781c-.383 0-.656.3-.656.656v3.938c0 .383.273.656.656.656h2.79l2.433 2.434a.654.654 0 0 0 1.121-.465v-9.16a.654.654 0 0 0-1.121-.465m6.371-1.422a.696.696 0 0 0-.93.191c-.191.328-.11.739.192.93 1.832 1.176 2.898 3.2 2.898 5.36a6.33 6.33 0 0 1-2.898 5.331c-.301.192-.383.602-.192.93a.71.71 0 0 0 .93.191c2.188-1.449 3.5-3.855 3.5-6.48 0-2.598-1.312-5.004-3.5-6.453m.875 6.453c0-1.723-.902-3.309-2.352-4.266a.64.64 0 0 0-.902.22c-.191.3-.11.71.191.901a3.73 3.73 0 0 1 1.75 3.145 3.74 3.74 0 0 1-1.75 3.172.657.657 0 0 0-.19.902c.163.301.573.41.901.219 1.45-.957 2.352-2.543 2.352-4.293m-3.883-2.078c-.328-.192-.71-.082-.902.246-.164.328-.055.71.273.902s.575.547.575.93c0 .41-.247.766-.575.957s-.437.574-.273.902a.643.643 0 0 0 .902.247 2.41 2.41 0 0 0 1.258-2.106c0-.848-.492-1.668-1.258-2.078"
    />
  </svg>
);
export default SvgVolume;
