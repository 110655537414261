import type { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m12.902 11.75 2.817-2.79.574-.573c.082-.082.082-.219 0-.328l-.602-.602c-.109-.082-.246-.082-.328 0L12 10.847l-3.39-3.39c-.083-.082-.22-.082-.329 0l-.601.602c-.082.109-.082.246 0 .328l3.39 3.363-3.39 3.39c-.082.083-.082.22 0 .329l.601.601c.11.082.246.082.328 0L12 12.68l2.79 2.816.573.574c.082.082.219.082.328 0l.602-.601c.082-.11.082-.246 0-.328z"
    />
  </svg>
);
export default SvgClose;
