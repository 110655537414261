import type { SVGProps } from 'react';
const SvgNotificationSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M19.75 12c0-4.25-3.5-7.75-7.75-7.75-4.281 0-7.75 3.5-7.75 7.75A7.75 7.75 0 0 0 12 19.75c4.25 0 7.75-3.469 7.75-7.75m-8.656 4.125a.53.53 0 0 1-.719 0l-3.25-3.25a.53.53 0 0 1 0-.719l.719-.687a.442.442 0 0 1 .687 0l2.219 2.187 4.688-4.687a.442.442 0 0 1 .687 0l.719.687a.53.53 0 0 1 0 .719z"
    />
  </svg>
);
export default SvgNotificationSuccess;
