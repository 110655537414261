import { FormEvent, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import giftImage from 'public/images/christmas2024/vinyl-shadow.avif';
import { TextButton } from 'src/components/buttons/text-button';
import Modal from 'src/components/modal';
import { useSubscriptionGiftModal } from 'src/hooks/use-modal';
import useTranslate from 'src/hooks/use-translate';
import { useImpressionTracking } from 'src/tracking';

/**
 * Renders a modal that is displayed after the user has paid for a subscription.
 * It offers a special gift to the user if they have subscribed to a yearly ticket.
 */
function SubscriptionGiftModalContent() {
  const t = useTranslate();
  const { close } = useSubscriptionGiftModal();
  const router = useRouter();
  const { impressionObserverRef, trackEvent } = useImpressionTracking({
    eventName: 'PostSubscriptionGiftModalImpression',
  });

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const buttonText: string | undefined =
      (event.currentTarget instanceof HTMLButtonElement && event.currentTarget.textContent) || undefined;

    // Track event
    trackEvent({ eventName: 'PostSubscriptionGiftModalSubmit', cta: buttonText });

    // Navigate to the claim gift page
    void router.push('/claim-gift');

    // Close the modal
    close();
  }

  function handleCancel(event: MouseEvent<HTMLButtonElement>) {
    const buttonText: string | undefined =
      (event.currentTarget instanceof HTMLButtonElement && event.currentTarget.textContent) || undefined;

    // Track event
    trackEvent({ eventName: 'PostSubscriptionGiftModalSubmit', cta: buttonText });

    // Close the modal
    close();
  }

  return (
    <div className="flex flex-col items-center gap-6" ref={impressionObserverRef}>
      <div className="flex flex-col items-center gap-3 text-center">
        <h1 className="dg-text-medium-2 whitespace-pre-line">{t('subscription_gift_modal__title')}</h1>
        <p className="dg-text-regular-3 text-greyG2">{t('subscription_gift_modal__description')}</p>
      </div>
      <img src={giftImage.src} alt="" width="171" height="134" className="-order-1" />

      <form className="flex justify-center gap-4" onSubmit={handleSubmit}>
        <TextButton type="button" variation="outline" onClick={handleCancel}>
          {t('subscription_gift_modal__cancel_button')}
        </TextButton>
        <TextButton type="submit" variation="secondary">
          {t('subscription_gift_modal__submit_button')}
        </TextButton>
      </form>
    </div>
  );
}

export default function SubscriptionGiftModal() {
  const { isOpen, close } = useSubscriptionGiftModal();
  return (
    <Modal open={isOpen} onClose={close} dataTest="subscription-gift-modal">
      {/* render the modal content only if we actually have the modal open */}
      <Modal.Content>
        <SubscriptionGiftModalContent />
      </Modal.Content>
    </Modal>
  );
}
