import type { SVGProps } from 'react';
const SvgFullscreenEnter = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11.672 13.39a.463.463 0 0 0 0-.628l-.684-.684a.463.463 0 0 0-.629 0l-2.515 2.516-.875-.875a.628.628 0 0 0-1.094.437v3.063c0 .383.273.656.629.656h3.062c.602 0 .903-.684.465-1.094l-.875-.875zm.629-3.253a.463.463 0 0 0 0 .629l.683.683a.463.463 0 0 0 .63 0l2.542-2.543.848.903c.41.437 1.121.136 1.121-.465V6.28c0-.355-.3-.656-.656-.656h-3.063c-.601 0-.902.71-.465 1.121l.903.848z"
    />
  </svg>
);
export default SvgFullscreenEnter;
