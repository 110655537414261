import { useCallback } from 'react';
import { isTouchDevice } from 'src/utilities/capabilities';

export const NativeShareUnavailableError = new Error('Native share unavailable');

export default function useNativeShare() {
  return useCallback(async (url: string) => {
    // On touch devices, e.g. mobile it’s worth using the native Share API as it has better conversion
    if (navigator?.share && isTouchDevice()) {
      try {
        return navigator.share({ url });
      } catch {
        throw NativeShareUnavailableError;
      }
    }

    throw NativeShareUnavailableError;
  }, []);
}
