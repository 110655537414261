import { useCallback, useEffect, useState } from 'react';

/**
 * A hook to persist data in the browsers storage, useful for local preferences
 * @param key a unique storage key identifier, make sure each stored unit has a unique name
 * @param initialValue an initial value
 * @returns current value and a setter, similar to useState hook
 */
const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    // do nothing on server side
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((value_: T) => T)) => {
      // do nothing on server side
      if (typeof window === 'undefined') {
        return;
      }
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error);
      }
    },
    [key, storedValue, setStoredValue],
  );

  // Observe local storage changes made in other tabs
  // WARNING: This won’t catch changes made by other instances of this hook
  // on the same page
  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === key) {
        setStoredValue(event.newValue ? (JSON.parse(String(event.newValue)) as T) : initialValue);
      }
    };
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [initialValue, key]);

  return [storedValue, setValue] as const;
};

export default useLocalStorage;
