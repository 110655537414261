import type { SVGProps } from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M15.5 13.125a.4.4 0 0 0-.375-.375H12.75v-2.375a.4.4 0 0 0-.375-.375h-.75a.38.38 0 0 0-.375.375v2.375H8.875a.38.38 0 0 0-.375.375v.75a.36.36 0 0 0 .375.375h2.375v2.375a.36.36 0 0 0 .375.375h.75a.38.38 0 0 0 .375-.375V14.25h2.375a.38.38 0 0 0 .375-.375zM19 7.5c0-.812-.687-1.5-1.5-1.5H16V4.375A.4.4 0 0 0 15.625 4h-1.25a.38.38 0 0 0-.375.375V6h-4V4.375A.4.4 0 0 0 9.625 4h-1.25A.38.38 0 0 0 8 4.375V6H6.5A1.5 1.5 0 0 0 5 7.5v11c0 .844.656 1.5 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5zm-1.5 10.813c0 .125-.094.187-.187.187H6.688q-.188 0-.188-.187V9h11z"
    />
  </svg>
);
export default SvgCalendar;
