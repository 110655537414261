import { RefObject, useCallback, useEffect } from 'react';

// detect if a HTML Element is inside one of the reference objects
const nodeIsInside = (refs: RefObject<HTMLDivElement>[], node: HTMLElement) => {
  const nodesDetected = refs.filter((ref) => ref.current?.contains(node));
  return nodesDetected.length > 0;
};

/**
 * Detect clicks outside of a given component
 * @hook
 * @param insideRef - reference to the 'inside' element
 * @param callback - a callback handler to be called once outside click happens
 * @param isActive - set to `true` once detection should start, e.g. when a modal is open.
 *
 */
const useClickOutside = ({
  ref,
  callback,
  isActive,
}: {
  ref: RefObject<HTMLDivElement> | RefObject<HTMLDivElement>[];
  callback: () => void;
  isActive: boolean;
}) => {
  // generic click handler, observing all the clicks
  const handleClick = (event: MouseEvent) => {
    // we accept both single reference and an array of references
    const insideReferences = Array.isArray(ref) ? ref : [ref];
    const targetNode = event.target as HTMLElement;
    // check where the click has happened
    if (targetNode && nodeIsInside(insideReferences, targetNode)) {
      // inside click
      return;
    }
    // outside click
    callback();
  };
  // the handler is cached so it gets recalculated only when the depencies have changed
  const memoizedHandler = useCallback(handleClick, [ref, callback]);
  // we listen to the clicks only when we have to, e.g. when some modal is open
  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', memoizedHandler);
    } else {
      document.removeEventListener('mousedown', memoizedHandler);
    }
    return () => {
      document.removeEventListener('mousedown', memoizedHandler);
    };
  }, [isActive, memoizedHandler]);
};

export default useClickOutside;
