import { useState, useEffect } from 'react';
import BackwardIcon from '@stageplus/icons/react/backward';
import Backward30SecIcon from '@stageplus/icons/react/backward-30-sec';
import ForwardIcon from '@stageplus/icons/react/forward';
import Forward30SecIcon from '@stageplus/icons/react/forward-30-sec';
import NextIcon from '@stageplus/icons/react/next';
import PreviousIcon from '@stageplus/icons/react/previous';
import { ButtonIconRound } from 'src/components/buttons/icon-button';
import PlayButton from 'src/components/play-button';
import useTranslate from 'src/hooks/use-translate';
import useVideoSkipping from 'src/hooks/use-video-skipping';
import { usePlayback } from 'src/state/playback';

/**
 * Provide a play button and content-specific skip/rewind buttons to the main site player.
 * The buttons are rendered or hidden based on the type of stream,
 * e.g. when live-streaming the 'skip to next' button is not shown and so on.
 * @component
 */
const SitePlayerControlsPlayback = () => {
  const t = useTranslate();

  const [controlsType, setControlsType] = useState<'single' | 'works' | 'playlist' | 'limited'>();

  // get the hook to the playback state
  const { currentWorks, currentMedia } = usePlayback();

  // the controls will look different depending on  the type of the media playing
  const currentMediaType = currentMedia?.__typename;
  // use a hook to do the media-aware skipping
  const { onSkipToPrevious, onSkipToNext, advanceSlightly, rewindSlightly } = useVideoSkipping();

  // we will switch the transport depending on the content type
  useEffect(() => {
    switch (currentMediaType) {
      case 'Album': {
        // for now we can assume that the audio albums are always a playlist
        setControlsType('playlist');
        break;
      }
      // keep in mind that on the live streams we won't render the skip buttons
      // Trailers have no skip buttons either
      case 'LiveConcert':
      case 'Trailer': {
        setControlsType('limited');
        break;
      }
      default: {
        if (currentWorks?.length) {
          setControlsType('works');
        } else {
          setControlsType('single');
        }
      }
    }
  }, [currentMediaType, currentWorks, setControlsType]);

  return (
    <>
      {controlsType === 'playlist' && (
        <ButtonIconRound
          title={t('player__button_previous')}
          icon={<PreviousIcon aria-hidden />}
          onClick={onSkipToPrevious}
          dataTest="button-previous-track"
        />
      )}
      {controlsType === 'works' && (
        <ButtonIconRound
          title={t('player__button_backward')}
          icon={<BackwardIcon aria-hidden />}
          onClick={onSkipToPrevious}
          dataTest="button-previous-work"
        />
      )}
      {controlsType === 'single' && (
        <ButtonIconRound
          title={t('player__button_rewind_30s')}
          icon={<Backward30SecIcon aria-hidden />}
          onClick={rewindSlightly}
        />
      )}

      {currentMedia && <PlayButton media={currentMedia} />}

      {controlsType === 'playlist' && (
        <ButtonIconRound
          title={t('player__button_next')}
          icon={<NextIcon aria-hidden />}
          onClick={onSkipToNext}
          dataTest="button-next-track"
        />
      )}
      {controlsType === 'works' && (
        <ButtonIconRound
          title={t('player__button_forward')}
          icon={<ForwardIcon aria-hidden />}
          onClick={onSkipToNext}
          dataTest="button-next-work"
        />
      )}
      {controlsType === 'single' && (
        <ButtonIconRound
          title={t('player__button_ff_30s')}
          icon={<Forward30SecIcon aria-hidden />}
          onClick={advanceSlightly}
        />
      )}
    </>
  );
};

export default SitePlayerControlsPlayback;
