import { ReactNode } from 'react';

type CommaSeparatedListProps<T> = {
  className?: string;
  list: T[] | undefined;
  renderItem: (argument0: T) => ReactNode;
  separator?: string;
};

/**
 * Takes an array and a render function and turns it into a comma separated list.
 * It allows you to do render React components as list items as opposed to a regular `array.join(',')`.
 * For simple lists, you should use the `listFormat` helper instead.
 *
 * @example
 * <CommaSeparatedList
 *   list={[
 *     { id: 1, name: 'Mozart' },
 *     { id: 2, name: 'Bach' },
 *   ]}
 *   renderItem={({ id, name }) => <span key={id}>{name}</span>}
 * />
 */
export default function CommaSeparatedList<T>({
  className,
  list,
  renderItem,
  separator = ', ', // default separator is a comma
}: CommaSeparatedListProps<T>) {
  return list ? (
    <span className={className}>{list.map((item, index) => [index > 0 && separator, renderItem(item)])}</span>
  ) : null;
}
