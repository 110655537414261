import { HTMLProps } from 'react';
import ContainerGrid, { ContainerGridProps } from 'src/components/container-grid';
import styles from 'src/components/spinner.module.css';

/**
 * An SVG spinner that can be used to indicate loading as provided by the design team
 * @see https://codepen.io/thom_rohr/pen/YzLZPwe
 */
const Spinner = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div data-test="spinner" {...props}>
      <svg viewBox="25 25 50 50" className={styles.svg}>
        <circle cx="50" cy="50" r="20" fill="none" stroke="rgba(255, 255, 255, 1)" strokeWidth="4" />
      </svg>
    </div>
  );
};

/**
 * A Spinner wrapped in a grid container, ready to be used on any <Page> directly
 */
export const ContainerSpinner = (containerGridProps: Omit<ContainerGridProps, 'children'>) => {
  return (
    <ContainerGrid {...containerGridProps}>
      <div className="col-span-full flex items-center justify-center">
        <Spinner className="size-12 md:size-16" />
      </div>
    </ContainerGrid>
  );
};

export default Spinner;
