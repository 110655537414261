import type { SVGProps } from 'react';
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20.125 5H3.875C2.82 5 2 5.86 2 6.875v11.25A1.85 1.85 0 0 0 3.875 20h16.25C21.141 20 22 19.18 22 18.125V6.875C22 5.859 21.14 5 20.125 5m0 1.875v1.602c-.898.742-2.305 1.835-5.273 4.18-.664.507-1.954 1.757-2.852 1.718-.937.04-2.227-1.21-2.89-1.719-2.97-2.344-4.376-3.437-5.235-4.18v-1.6zm-16.25 11.25v-7.227c.86.704 2.148 1.72 4.063 3.243.859.664 2.382 2.148 4.062 2.109 1.64.04 3.125-1.445 4.023-2.11a647 647 0 0 1 4.102-3.242v7.227z"
    />
  </svg>
);
export default SvgMail;
