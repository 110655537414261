import { useEffect } from 'react';
import NotificationToast from 'src/components/notification-toast';
import { useNotification } from 'src/hooks/use-notification';

/**
 * Manages display and hiding of the notification toast
 * Should be added in the main `App` component
 */
export default function NotificationManager() {
  const [isNotificationOpen, hideNotification] = useNotification((state) => [state.open, state.hideNotification]);
  const notificationProperties = useNotification((state) => state.notification);

  useEffect(() => {
    let hideTimeout: NodeJS.Timeout;
    // in case the notification is temporary, hide it after x seconds
    if (notificationProperties?.hideAfterSeconds && notificationProperties?.hideAfterSeconds > 0) {
      hideTimeout = setTimeout(() => hideNotification(), notificationProperties?.hideAfterSeconds * 1000);
    }
    return () => hideTimeout && clearTimeout(hideTimeout);
  }, [notificationProperties, hideNotification]);

  return (
    <NotificationToast
      title={notificationProperties?.title}
      text={notificationProperties?.text}
      type={notificationProperties?.type}
      open={isNotificationOpen}
      actionUI={notificationProperties?.action}
      onClose={hideNotification}
    />
  );
}
