import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CuePoint } from 'generated/graphql';

type TimelineTooltipProps = {
  position: number;
  label?: string;
  active: boolean;
};

/**
 * A tooltip displayed on the player timeline hovering
 * it adjusts the display automatically based on the relative position to the timeline
 * @component
 *  @param position - relative position of the tooltip, a float number between `0` and `1`
 *  @param label - label to be displayed in the tooltip
 *  @param active - display or hide the tooltip?
 */

const TimelineTooltip = ({ position, label, active }: TimelineTooltipProps) => {
  return (
    <div
      className={clsx(
        'absolute -top-11 transform-gpu select-none whitespace-nowrap transition-opacity duration-200',
        active ? 'opacity-100' : 'opacity-0',
      )}
      style={{ left: `${(active ? position : -2) * 100}%`, filter: 'drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.45))' }}
    >
      <div
        className="mb-1.5 transform-gpu rounded-sm bg-mainBgBlueC2 px-3 py-1"
        style={{ transform: `translateX(-${position * 100}%)` }}
      >
        <span className="dg-text-regular-4 text-white opacity-70">{label}</span>
      </div>
      <svg
        width="17"
        height="7"
        viewBox="0 0 17 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute bottom-0 -mt-2 text-mainBgBlueC2"
        style={{ left: '-3px' }}
      >
        <path d="M0.5 0H16.5L10 6.38926C9.17157 7.20358 7.82843 7.20358 7 6.38926L0.5 0Z" fill="currentColor" />
      </svg>
    </div>
  );
};

type CuePointIndicatorProps = {
  position: number;
  id: number;
  highlighted?: boolean;
  onMouseEnter: (id: number) => void;
  onMouseLeave: () => void;
};

/**
 *
 * @component
 * @param position - a relative position of the indicator, a float number between `0` and `1`
 * @param id - the unique id of the particular cue point
 * @param highlighted - wherever the indicator should be painted in a bright color
 * @param onMouseEnter - callback triggered when user hovers over the cue point indicator
 * @param onMouseLeave - callback triggered when user cursor leaves the cue point indicator
 */

const CuePointIndicator = ({
  position,
  id,
  highlighted,
  onMouseEnter,
  onMouseLeave,
}: CuePointIndicatorProps): JSX.Element => {
  return (
    <div
      className={clsx(
        'absolute top-0 size-2.5 transform-gpu cursor-pointer rounded-full',
        highlighted ? 'bg-brandYellowC1' : 'bg-white',
      )}
      style={{ left: `${position * 100}%` }}
      onMouseEnter={() => onMouseEnter(id)}
      onMouseLeave={() => onMouseLeave()}
    ></div>
  );
};

const Timeline = ({
  highlighted,
  cuePoints,
  duration = 0,
}: {
  highlighted?: boolean;
  cuePoints?: CuePoint[];
  duration?: number;
}) => {
  // remember which cue point is currently activated
  const [activePointId, setActivePointId] = useState<number>(-1);

  const [activeChapterTitle, setActiveChapterTitle] = useState<string>();

  // get the title of the cue point based on the currently active ID
  useEffect(() => {
    const activeWork = cuePoints?.[activePointId];
    setActiveChapterTitle(activeWork?.title || '');
  }, [cuePoints, activePointId]);

  // what happens when user hovers over the cue point indicator
  const pointEnterHandler = (id: number) => {
    setActivePointId(id);
  };
  // and when user leaves the indicator
  const pointLeaveHandler = () => {
    setActivePointId(-1);
  };

  return (
    <div className="relative h-1 select-none">
      <div className={highlighted ? 'opacity-100' : 'opacity-50'}>
        <div className={clsx('absolute inset-x-0 h-1 rounded-lg', highlighted ? 'bg-brandYellowC1' : 'bg-white')}></div>
        <div className="absolute top-0 w-full" style={{ top: '-3px' }}>
          {duration
            ? // display the cue points only when the duration is known, otherwise the position would be a 0
              cuePoints?.map((point, index) => (
                <CuePointIndicator
                  highlighted={highlighted}
                  position={point.mark / duration}
                  id={index}
                  key={index}
                  onMouseEnter={pointEnterHandler}
                  onMouseLeave={pointLeaveHandler}
                />
              ))
            : undefined}
        </div>
      </div>
      <TimelineTooltip
        label={activeChapterTitle}
        position={(cuePoints && cuePoints[activePointId]?.mark / duration) || 0}
        active={activePointId >= 0}
      />
    </div>
  );
};

export default Timeline;
