import { useState, useEffect } from 'react';
import { isPortraitOrientation } from 'src/utilities/capabilities';

/**
 * A hook to see if the current device is in a portrait or landscape mode
 * @returns `portrait` or `landscape`
 */
export const useScreenOrientation = () => {
  const [isPortrait, setIsPortrait] = useState(isPortraitOrientation());
  useEffect(() => {
    const handleViewportSizeChange = () => {
      setIsPortrait(isPortraitOrientation());
    };
    window.addEventListener('resize', handleViewportSizeChange);
    window.screen?.orientation?.addEventListener('change', handleViewportSizeChange);
    return () => {
      window.removeEventListener('resize', handleViewportSizeChange);
      window.screen?.orientation?.removeEventListener('change', handleViewportSizeChange);
    };
  }, [isPortrait]);

  return isPortrait ? 'portrait' : 'landscape';
};
