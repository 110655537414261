import { useCallback, useEffect, useState } from 'react';

type CopyStatus = 'pending' | 'success' | 'error';

/**
 * React hook that returns a function that copies the given text to the clipboard.
 */
export default function useCopyToClipboard(timeout = 3000): [CopyStatus, (text: string) => void] {
  const [copyStatus, setCopyStatus] = useState<CopyStatus>('pending');

  const copy = useCallback(async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyStatus('success');
    } catch {
      setCopyStatus('error');
    }
  }, []);

  useEffect(() => {
    if (copyStatus === 'pending') {
      return;
    }

    const timeoutId = setTimeout(() => setCopyStatus('pending'), timeout);

    return () => clearTimeout(timeoutId);
  }, [copyStatus, timeout]);

  return [copyStatus, copy];
}
