import type { SVGProps } from 'react';
const SvgNotificationError = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17.5 5h-11A1.5 1.5 0 0 0 5 6.5v11c0 .844.656 1.5 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-11c0-.812-.687-1.5-1.5-1.5M11 8h1.969c.219 0 .406.188.375.406l-.219 4.25c-.031.219-.187.344-.375.344h-1.531c-.188 0-.344-.125-.375-.344l-.219-4.25C10.594 8.187 10.781 8 11 8m1 8.438A1.414 1.414 0 0 1 10.563 15c0-.781.624-1.437 1.437-1.437.781 0 1.438.656 1.438 1.437 0 .813-.657 1.438-1.438 1.438"
    />
  </svg>
);
export default SvgNotificationError;
