import useSWR from 'swr';
import CouponCodeForm from 'src/components/forms/coupon-code-form';
import Modal from 'src/components/modal';
import SubscriptionPlans from 'src/components/subscription-plans';
import { useSubscriptionModal } from 'src/hooks/use-modal';
import useSdk from 'src/hooks/use-sdk';
import useTicketStatus from 'src/hooks/use-ticket-status';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';

/**
 * Subscription Plans Modal that will show the subscription plans and the coupon code form
 */
function SubscriptionModalContent() {
  const sdk = useSdk();
  const { data } = useSWR('subscriptionModal', () => sdk.subscriptionPlans());
  const { isValid: userHasSubscription } = useTicketStatus();

  if (!data) return null;

  return (
    // define for the tracking where we display the subscription plans
    <TrackingContext.Provider value={TrackingContextValues.SubscriptionModal}>
      <SubscriptionPlans subscriptionPlans={data.subscriptionPlansStripe.plans} />
      {!userHasSubscription && <CouponCodeForm />}
    </TrackingContext.Provider>
  );
}

export default function SubscriptionModal() {
  const { isOpen, close } = useSubscriptionModal();

  return (
    <Modal open={isOpen} onClose={close} dataTest="subscription-modal" wide>
      <Modal.Content>
        <SubscriptionModalContent />
      </Modal.Content>
    </Modal>
  );
}
