import type { SVGProps } from 'react';
const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 4.25c-4.281 0-7.75 3.5-7.75 7.75A7.75 7.75 0 0 0 12 19.75c4.25 0 7.75-3.469 7.75-7.75 0-4.25-3.5-7.75-7.75-7.75m0 3.438A1.32 1.32 0 0 1 13.313 9 1.3 1.3 0 0 1 12 10.313c-.75 0-1.312-.563-1.312-1.313A1.3 1.3 0 0 1 12 7.688m1.75 7.937a.38.38 0 0 1-.375.375h-2.75a.36.36 0 0 1-.375-.375v-.75a.38.38 0 0 1 .375-.375H11v-2h-.375a.36.36 0 0 1-.375-.375v-.75a.38.38 0 0 1 .375-.375h2c.188 0 .375.188.375.375V14.5h.375c.188 0 .375.188.375.375z"
    />
  </svg>
);
export default SvgNotification;
