import { SubmitHandler, useForm } from 'react-hook-form';
import { TextButton } from 'src/components/buttons/text-button';
import { useShowNotification } from 'src/hooks/use-notification';
import useSdk from 'src/hooks/use-sdk';
import useSession from 'src/hooks/use-session';
import useTranslate from 'src/hooks/use-translate';
import { useImpressionTracking } from 'src/tracking';
import { captureException } from 'src/utilities/exceptions';

type ResendEmailConfirmationFormProps = {
  onSubmitSuccess: () => void;
};

type FormValues = {
  newEmail: string;
  repeatNewEmail: string;
};

const ResendEmailConfirmationForm = ({ onSubmitSuccess }: ResendEmailConfirmationFormProps) => {
  const t = useTranslate();
  const { data: sessionData } = useSession();
  const userEmail = sessionData?.currentUser?.email;

  // prepare to display a notification toast if needed
  const showNotification = useShowNotification();

  const sdk = useSdk();
  const { handleSubmit, formState } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async () => {
    if (!userEmail) {
      return;
    }
    try {
      // ask the server to send a new confirmation email
      await sdk.resendEmailConfirmation();
      // show a success message
      showNotification({
        type: 'success',
        title: '',
        text: t('form_resend_email__instruction'),
        hideAfterSeconds: 15,
      });
      onSubmitSuccess();
    } catch (error) {
      // show a generic error message
      showNotification({
        title: t('notification__error_generic_title'),
        text: t('notification__error_generic_text'),
        type: 'error',
        hideAfterSeconds: 15,
      });
      // and log the error to Sentry
      captureException(error);
    }
  };

  // track impression of the required confirmation email form
  const { impressionObserverRef } = useImpressionTracking({
    eventName: 'SignupConfirmationRequired',
  });

  return (
    <div data-test="resend-confirmation-form" className="text-center text-white">
      <h1 className="dg-text-medium-2 mb-4">{t('form_resend_email__headline')}</h1>
      <div className="dg-text-regular-6 opacity-70" ref={impressionObserverRef}>
        <p className="mb-6">{t('form_resend_email__instruction')}</p>
        <p className="mb-6">
          {t('form_resend_email__your_current')}
          <br />
          {userEmail}
        </p>
        <p className="mb-4">{t('form_resend_email__not_receiving')}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="inline-block">
        <TextButton
          type="submit"
          data-test="button-resend-email-submit"
          variation="secondary"
          loading={formState.isSubmitting}
          fullWidth
        >
          {t('form_resend_email__submit')}
        </TextButton>
      </form>
    </div>
  );
};

export default ResendEmailConfirmationForm;
