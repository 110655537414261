import { useEffect, useState, useRef } from 'react';
import FacebookIcon from '@stageplus/icons/react/facebook';
import LinkedinIcon from '@stageplus/icons/react/linkedin';
import MailIcon from '@stageplus/icons/react/mail';
import XIcon from '@stageplus/icons/react/x';
import useSWR from 'swr';
import { TextButton } from 'src/components/buttons/text-button';
import Modal from 'src/components/modal';
import useCopyToClipboard from 'src/hooks/use-copy-to-clipboard';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { useShareModal } from 'src/hooks/use-modal';
import useNativeShare, { NativeShareUnavailableError } from 'src/hooks/use-native-share';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { trackEvent } from 'src/tracking';
import { getSocialMediaHandles } from 'src/utilities/social-media-helpers';
import { getNodePath } from 'src/utilities/url-helpers';

/**
 * Share modal triggering either a native share dialog or displaying a custom one
 */
function ShareModalContent() {
  const t = useTranslate();
  const currentLocale = useCurrentLocale();
  const sdk = useSdk();
  const { data, isOpen, close } = useShareModal();
  const shareModalRequest = useSWR(data ? `shareModal/${data.id}` : null, () =>
    sdk.shareModal({ nodeId: data?.id || '' }),
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const openNativeShare = useNativeShare();
  const [copyStatus, copyToClipboard] = useCopyToClipboard();
  // Whether to show the custom share modal when native share not available
  const [isCustomShareOpen, setIsCustomShareOpen] = useState<boolean>(false);
  const nodePath = data && getNodePath(data);
  // The node object to be shared
  const shareNode = shareModalRequest.data?.node;
  // The share url is a deep link to the node page
  const shareUrl = nodePath ? `${window.location.origin}${nodePath}?utm_source=share` : undefined;
  // The title of the node to be shared
  const title =
    (shareNode && 'title' in shareNode && shareNode.title) ||
    (shareNode && 'name' in shareNode && shareNode.name) ||
    (shareNode && 'work' in shareNode && shareNode.work.title) ||
    '';

  // The email share url is a mailto link containing a share subject and body
  const emailShareUrl = shareNode
    ? `mailto:?subject=${encodeURIComponent(t('share__email_subject', [title]))}&body=${encodeURIComponent(
        t('share__email_body_i18next', {
          url: shareUrl,
          title,
        }),
      )}`
    : undefined;

  // the labels on the copy button change according to user's actions
  const copyButtonText = {
    pending: t('share__copy'),
    success: t('share__copy_success'),
    error: t('share__copy_error'),
  }[copyStatus];

  function handleCopyClick() {
    if (inputRef.current) {
      inputRef.current.select();
      copyToClipboard(inputRef.current.value);
      trackEvent({ eventName: 'Share', network: 'UrlCopy' });
    }
  }

  /**
   * Try to open the native share options of the device.
   * If not available enable custom share modal.
   */
  useEffect(() => {
    async function share() {
      if (isOpen) {
        if (!shareUrl) return;

        try {
          // Try to open a native share sheet
          await openNativeShare(shareUrl);
          // track the share event automatically as there's no other way to know which share button was clicked
          trackEvent({ eventName: 'Share', network: 'Other' });
          // Close the share modal on native share success
          close();
        } catch (error) {
          // Enable custom share when native share unavailable
          if (error === NativeShareUnavailableError) {
            setIsCustomShareOpen(true);
          }
          // Close share modal when user dismissed native share
          else {
            close();
          }
        }
      }
    }
    // open the native share dialog,
    void share();
  }, [isOpen, shareUrl, setIsCustomShareOpen, close, openNativeShare]);

  /**
   * Keep the state of the custom share modal in sync with the main share modal
   * state.
   */
  useEffect(() => {
    // Always disable custom share modal when share modal is closed
    if (!isOpen) {
      setIsCustomShareOpen(false);
    }
  }, [isOpen, setIsCustomShareOpen]);

  return (
    <Modal open={isCustomShareOpen} onClose={close} dataTest="share-modal">
      <Modal.Content>
        <div className="text-center">
          <h1 className="dg-text-medium-3 mb-14">{t('listitem__share')}</h1>

          <div className="flex flex-col items-center space-y-10">
            <div className="flex flex-wrap items-center justify-center gap-2">
              {shareUrl && (
                <>
                  {/* facebook share button */}
                  <a
                    className="flex items-center justify-center rounded focus-visible:focus-outline"
                    style={{ width: 72, height: 46, backgroundColor: '#4267B2' }}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test="share-facebook"
                    title={t('share__facebook')}
                    onClick={() => trackEvent({ eventName: 'Share', network: 'Facebook' })}
                  >
                    <FacebookIcon aria-hidden />
                  </a>
                  {/* twitter/X share button */}
                  <a
                    className="flex items-center justify-center rounded focus-visible:focus-outline"
                    style={{ width: 72, height: 46, backgroundColor: '#000000' }}
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&via=${
                      getSocialMediaHandles(currentLocale).twitter
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test="share-twitter"
                    title={t('share__twitter')}
                    onClick={() => trackEvent({ eventName: 'Share', network: 'Twitter' })}
                  >
                    <XIcon aria-hidden />
                  </a>
                  {/* linkedin share button */}

                  <a
                    className="flex items-center justify-center rounded focus-visible:focus-outline"
                    style={{ width: 72, height: 46, backgroundColor: '#0077B5' }}
                    href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(shareUrl)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-test="share-linkedin"
                    title={t('share__linkedin')}
                    onClick={() => trackEvent({ eventName: 'Share', network: 'LinkedIn' })}
                  >
                    <LinkedinIcon aria-hidden />
                  </a>
                </>
              )}
              {/* email share button */}
              {emailShareUrl && (
                <a
                  className="flex items-center justify-center rounded bg-buttonBlueC8 focus-visible:focus-outline"
                  style={{ width: 72, height: 46 }}
                  href={emailShareUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test="share-email"
                  title={t('share__email')}
                  onClick={() => trackEvent({ eventName: 'Share', network: 'Email' })}
                >
                  <MailIcon aria-hidden />
                </a>
              )}
            </div>

            <div className="flex w-full items-center justify-between space-x-2">
              {/* Input field to copy url */}
              <input
                className="dg-text-regular-4 h-11 flex-1 rounded border-none bg-darkBlueC7 px-3 focus-visible:focus-outline lg:h-[52px]"
                type="text"
                value={shareUrl}
                ref={inputRef}
                data-test="share-input"
                readOnly
              />
              {/* copy share url button */}
              <TextButton
                variation="secondary"
                onClick={handleCopyClick}
                className="dg-text-regular-6 w-auto"
                data-test="share-copy"
              >
                {copyButtonText}
              </TextButton>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default function ShareModal() {
  const { isOpen, close } = useShareModal();
  return (
    <Modal open={isOpen} onClose={close} dataTest="reminder-modal">
      <Modal.Content>
        <ShareModalContent />
      </Modal.Content>
    </Modal>
  );
}
