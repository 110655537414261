import { Fragment } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { TextButton } from 'src/components/buttons/text-button';
import useTranslate from 'src/hooks/use-translate';
import { useConfirmState } from 'src/state/confirm';

/**
 * Renders a custom confirmation dialog based on the `useConfirmState` state.
 */
export default function Confirm() {
  const t = useTranslate();
  const { open, prompt, confirm, cancel, destructive, message } = useConfirmState((state) => state.properties);
  const closeConfirm = useConfirmState((state) => state.closeConfirm);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog className="relative z-50" onClose={closeConfirm} data-test="confirm">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-overlay" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md overflow-hidden rounded-2xl bg-mainBgBlueC2 p-6 text-left align-middle shadow-xl transition-all md:p-8">
                <h2 className="dg-text-medium-3 mb-2 text-center">{prompt}</h2>
                {message && <p className="dg-text-regular-6 text-center opacity-70">{message}</p>}

                <div className="flex justify-center space-x-4 px-6 pt-6 md:px-12">
                  <TextButton
                    type="button"
                    onClick={cancel}
                    data-test="confirm-cancel-button"
                    variation="outline"
                    fullWidth
                  >
                    {t('confirm__cancel')}
                  </TextButton>
                  <TextButton
                    type="button"
                    onClick={confirm}
                    data-test="confirm-confirm-button"
                    variation={destructive ? 'danger' : 'secondary'}
                    fullWidth
                  >
                    {t('confirm__confirm')}
                  </TextButton>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
