import { useCallback } from 'react';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { trackPlayback } from 'src/tracking/track-playback';

/**
 * A hook that enables content-based skipping in the currently playing video.
 */
const useVideoSkipping = () => {
  // get the hook to the playback state
  const {
    skipToNext,
    skipToPrevious,
    skipToFirst,
    currentActiveWork,
    currentActiveTrack,
    currentWorks,
    currentTracks,
    currentMedia,
  } = usePlayback();

  // hooks into the video element
  const { seek, videoRef, audio } = useVideoContext();

  // tracking hook for the media playback events

  // the amount time for multiple skipping clicks, give user some time click through
  const skipTolerance = 4;
  //  check if the album or concert have more tracks
  const isMultiTrack = (currentWorks && currentWorks.length > 1) || (currentTracks && currentTracks.length > 1);

  const trackSkip = useCallback(() => {
    // track the skipping to the next track or work
    trackPlayback({
      eventName: 'PlaybackSkip',
      currentMedia,
      currentActiveWork,
      currentActiveTrack,
      audioQuality: audio?.currentAudioTrack?.label,
    });
  }, [audio?.currentAudioTrack?.label, currentActiveTrack, currentActiveWork, currentMedia]);

  /**
   * Find the closest cue point in the currently playing performance work.
   */
  const getClosestCuePoint = useCallback(
    (direction: 'next' | 'previous') => {
      // only works have cue points currently
      if (!currentActiveWork || !currentActiveWork.cuePoints) {
        return;
      }
      // get the current playback time
      const currentTime = videoRef.current?.currentTime ?? 0;

      if (direction === 'next') {
        // find the next cue point after the current time
        return currentActiveWork.cuePoints.find((cuePoint) => cuePoint.mark > currentTime);
      } else {
        //  find all previous cue points before the current time
        const previousCuePoints = currentActiveWork.cuePoints.filter((cuePoint) => cuePoint.mark < currentTime);
        // return the last one
        const firstClosestCuePoint = previousCuePoints.pop();
        // return the last one before that
        const secondClosestCuePoint = previousCuePoints.pop();
        // if user skips quickly, within 2 seconds of the last cue point, we want to skip to the one before that
        return firstClosestCuePoint && currentTime > firstClosestCuePoint?.mark + skipTolerance
          ? firstClosestCuePoint
          : secondClosestCuePoint;
      }
    },
    [currentActiveWork, videoRef],
  );

  const advanceSlightly = useCallback(() => {
    seek((currentTime: number) => currentTime + 30);
  }, [seek]);

  const rewindSlightly = useCallback(() => {
    seek((currentTime: number) => currentTime - 30);
  }, [seek]);

  // skipping is a special case, if there are any cue points for the movements,
  // we'll go through those first before skipping to the next track or work.
  const onSkipToPrevious = useCallback(() => {
    //  get the current playback time
    const currentTime = videoRef.current?.currentTime ?? 0;
    // find the previous cuePoint
    const previousCuePoint = getClosestCuePoint('previous');

    if (previousCuePoint) {
      // if we know the previous cue point, we can skip to it
      // if the video is playing, jump slightly before the cue point,
      // otherwise we'll be stuck at that point
      seek(previousCuePoint.mark);
    } else if (currentTime > skipTolerance) {
      // if there is no previous cue point, and the video has progressed a bit,
      // rewind the video to the beginning
      seek(0);
    } else {
      // if the user has clicked on rewind during the first few seconds of the video,
      // try to jump back to the previous track or work
      const previousWorkId = skipToPrevious();
      // rewind the video to the beginning if there is no work to skip to
      if (!previousWorkId) {
        seek(0);
      }
    }
  }, [getClosestCuePoint, seek, skipToPrevious, videoRef]);

  const onSkipToNext = useCallback(() => {
    // track the skipping to the next track or work, note that backwards skipping is not tracked
    trackSkip();
    // find the next cuePoint and skip to it, if possible
    const nextCuePoint = getClosestCuePoint('next');
    if (nextCuePoint) {
      seek(nextCuePoint.mark);
    } else {
      // and if there is no next cue point, we'll try to skip to the next track or work
      const nextWorkId = skipToNext();
      // if there is no work to skip to
      if (!nextWorkId) {
        // rewind back to the beginning
        seek(0);
        // if it's a multitrack, go to the first track of the concert or the album
        if (isMultiTrack) {
          skipToFirst();
        }
      }
    }
  }, [trackSkip, getClosestCuePoint, seek, skipToNext, isMultiTrack, skipToFirst]);

  return {
    /**
     * Skip to the previous track or work or cue point in the work.
     */
    onSkipToPrevious,
    /**
     * Skip to the next track or work or cue point in the work.
     */
    onSkipToNext,
    /**
     * Fast forward the video by 30 seconds.
     */
    advanceSlightly,
    /**
     * Rewind the video by 30 seconds.
     */
    rewindSlightly,
  };
};

export default useVideoSkipping;
