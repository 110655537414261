import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ForgotPasswordForm from 'src/components/forms/forgot-password-form';
import LoginForm from 'src/components/forms/login-form';
import ResendEmailConfirmationForm from 'src/components/forms/resend-confirmation-form';
import SignupForm from 'src/components/forms/signup-form';
import Modal, { ModalProps } from 'src/components/modal';
import { useAuthModal } from 'src/state/auth';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';

/**
 * ModalAuth contains all possible login and signup flow states
 * To open it, you just have to set the auth modal state accordingly
 * It is available across the whole web app
 */
export default function ModalAuth() {
  const { replace, pathname } = useRouter();
  const { currentAuthModal, close } = useAuthModal();

  // check if user has entered something in the auth form
  const [formHasChanged, setFormHasChanged] = useState(false);

  const onClose: ModalProps['onClose'] = (explicit?: boolean) => {
    // if the user has typed something into the form, they have to explicitly close the modal via the button click
    if (formHasChanged && !explicit) {
      return;
    }
    close();
  };

  // register if the form has changed by the user
  const onFormChange = () => {
    setFormHasChanged(true);
  };
  // redirect the page to the discover page if the user is on the landing page
  const onComplete = () => {
    if (pathname === '/') {
      void replace('/discover');
    }
    onClose(true);
  };

  // but reset the changed state when the modal is switched or closed intentionally
  useEffect(() => {
    setFormHasChanged(false);
  }, [currentAuthModal, setFormHasChanged]);

  return (
    <Modal open={currentAuthModal !== undefined} onClose={onClose}>
      <Modal.Content>
        {currentAuthModal === 'login' && (
          <TrackingContext.Provider value={TrackingContextValues.LoginModal}>
            <LoginForm onLoginSuccess={onComplete} onChange={onFormChange} />
          </TrackingContext.Provider>
        )}
        {currentAuthModal === 'signup' && (
          <TrackingContext.Provider value={TrackingContextValues.SignupModal}>
            <SignupForm onSubmitSuccess={onComplete} onChange={onFormChange} />
          </TrackingContext.Provider>
        )}
        {currentAuthModal === 'forgotpassword' && (
          <TrackingContext.Provider value={TrackingContextValues.ForgotPasswordModal}>
            <ForgotPasswordForm onSubmitSuccess={() => onClose(true)} />
          </TrackingContext.Provider>
        )}
        {currentAuthModal === 'verify' && (
          <TrackingContext.Provider value={TrackingContextValues.SignupConfirmationRequiredModal}>
            <ResendEmailConfirmationForm onSubmitSuccess={() => onClose(true)} />
          </TrackingContext.Provider>
        )}
      </Modal.Content>
    </Modal>
  );
}
