import { useEffect, useState, createContext, useRef, useContext } from 'react';
import { useRouter } from 'next/router';
import SitePlayerError from 'src/components/site-player-error';
import SitePlayerMaxi from 'src/components/site-player-maxi';
import SitePlayerMini from 'src/components/site-player-mini';
import SitePlayerVideoElement from 'src/components/site-player-video-element';
import { useMediaSessionApi } from 'src/hooks/use-media-session-api';
import { usePlayerContent } from 'src/hooks/use-player-content';
import { useVideoProgressMemory } from 'src/hooks/use-video-progress-memory';
import { usePlayback } from 'src/state/playback';
import { ErrorTypes } from 'src/utilities/hls-js-extracted';
import { noop } from 'src/utilities/noop';
import { getTrackFromUrl, isPlayerUrl } from 'src/utilities/url-helpers';

const SitePlayerContextInitial = {
  trackListVisible: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTrackListVisible: (_on: boolean) => noop(),
};
// site player context allows to adjust to the player changes from the outside
const SitePlayerContext = createContext(SitePlayerContextInitial);

// the site video player
const SitePlayer = () => {
  // the player will fetch the necessary content automatically when we enter the player page
  const { playerData, isContentGeoBlocked } = usePlayerContent();

  // player URL operations
  const { events: routerEvents } = useRouter();
  // prepare the playback queue operations
  const { playbackQueue, playerMode, setPlayerMode, activateItem } = usePlayback();

  // the player will be visible when the queue is not empty
  const playContentLoaded = playbackQueue.media.length > 0;

  // a shorthand for the mini player detection
  const isMiniPlayer = playerMode === 'mini';

  // a reference to the player container, needed for the fullscreen API
  const playerRef = useRef(null);

  const [trackListVisible, setTrackListVisible] = useState(false);

  // automatically store and restore the stream progress when needed
  useVideoProgressMemory();

  // use the media session API to display the player info in the browser
  useMediaSessionApi();

  // adjust the player display mode automatically when we enter or leave the player page
  // this will handle the player changes when the user is navigating using the browsers back/forward buttons
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const enteringPlayerURL = isPlayerUrl(url);
      const track = getTrackFromUrl(url);
      // reset the player to mini if it was in maxi window mode previously
      if (playerMode === 'mini' && enteringPlayerURL) {
        setPlayerMode('maxi');
        // reset the player to maxi when re-entering the player page
      } else if (playerMode === 'maxi' && !enteringPlayerURL) {
        setPlayerMode('mini');
      }
      // sync the active item when the url changes, e.g. when user selects a different track
      if (playbackQueue.activeMediaId && track && track !== playbackQueue.activeItemId) {
        activateItem(playbackQueue.activeMediaId, track);
      }
    };
    // add the listener to the router events
    routerEvents.on('routeChangeStart', handleRouteChange);
    return () => {
      routerEvents.off('routeChangeStart', handleRouteChange);
    };
  }, [setPlayerMode, playerMode, routerEvents, playbackQueue, activateItem, playerData]);

  return (
    <SitePlayerContext.Provider value={{ trackListVisible, setTrackListVisible }}>
      <div className="pointer-events-none fixed inset-0 z-40 w-full" data-test="site-player" ref={playerRef}>
        <div className="absolute inset-0">
          {/* The main video tag has to be always present for the autoplay to work in safari etc.
           The element will receive a user-triggered action (play or load) first, even before the video source is set.
           The action will give a permission to start playback automatically once the stream is loaded */}
          <SitePlayerVideoElement />
          {playContentLoaded &&
            /* the player UI is displayed only if we have the data */
            (isMiniPlayer ? <SitePlayerMini /> : <SitePlayerMaxi playerRef={playerRef} />)}
          {/* the Geo-Blocked message if we have to display one */}
          {isContentGeoBlocked && (
            <SitePlayerError errorDisplayType="GeoError" error={{ errorType: ErrorTypes.OTHER_ERROR, code: 451 }} />
          )}
        </div>
      </div>
    </SitePlayerContext.Provider>
  );
};

export const useSitePlayerContext = () => {
  const context = useContext(SitePlayerContext);
  if (!context) {
    throw new Error('useSitePlayerContext must be used within a SitePlayerContextProvider');
  }
  return context;
};

export default SitePlayer;
