import type { SVGProps } from 'react';
const SvgCheckmarkTwoTone = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g fill="currentColor">
      <path d="M20.48 3.51A12 12 0 0 1 12 24v-1.5a10.5 10.5 0 1 0 0-21V0a12 12 0 0 1 8.48 3.51" opacity={0.8} />
      <path d="M12 0v1.5a10.5 10.5 0 0 0 0 21V24a12 12 0 0 1 0-24" opacity={0.4} />
      <path fillRule="evenodd" d="m11 16-4.688-4.687 1.111-1.111 4.687 4.687z" clipRule="evenodd" opacity={0.6} />
      <path fillRule="evenodd" d="m9.89 14.889 6.586-6.586 1.11 1.111L11.002 16z" clipRule="evenodd" />
    </g>
  </svg>
);
export default SvgCheckmarkTwoTone;
