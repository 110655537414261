import type { SVGProps } from 'react';
const SvgNext = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M15 6.321c0-.16-.15-.321-.3-.321h-1.2c-.175 0-.3.16-.3.321v4.71L8.3 6.214c-.525-.455-1.3-.08-1.3.642v10.277c0 .75.775 1.124 1.3.67l4.9-4.845v4.71c0 .188.125.322.3.322h1.2c.15 0 .3-.134.3-.322z"
    />
  </svg>
);
export default SvgNext;
