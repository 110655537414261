import type { SVGProps } from 'react';
const SvgForward = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m19.672 11.094-5.25-4.375C13.848 6.254 13 6.637 13 7.375v8.75a.866.866 0 0 0 1.422.684l5.25-4.375a.883.883 0 0 0 0-1.34m-7 0-5.25-4.375C6.848 6.254 6 6.637 6 7.375v8.75a.866.866 0 0 0 1.422.684l5.25-4.375a.883.883 0 0 0 0-1.34"
    />
  </svg>
);
export default SvgForward;
