import { Fragment, SyntheticEvent, useRef } from 'react';
import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import AirplayIcon from '@stageplus/icons/react/airplay';
import AudioIndicatorAtmos from '@stageplus/icons/react/audio-indicator-atmos';
import AudioIndicatorLossless from '@stageplus/icons/react/audio-indicator-lossless';
import ChevronDownIcon from '@stageplus/icons/react/chevron-down';
import CloseIcon from '@stageplus/icons/react/close';
import FullscreenEnterIcon from '@stageplus/icons/react/fullscreen-enter';
import FullscreenExitIcon from '@stageplus/icons/react/fullscreen-exit';
import PauseIcon from '@stageplus/icons/react/pause';
import PictureInPictureIcon from '@stageplus/icons/react/picture-in-picture';
import PlayIcon from '@stageplus/icons/react/play';
import SettingsIcon from '@stageplus/icons/react/settings';
import WorklistIcon from '@stageplus/icons/react/worklist';
import clsx from 'clsx';
import Link from 'next/link';
import AudioList from 'src/components/audio-list';
import FavoriteButton from 'src/components/buttons/favorite-button';
import { ButtonIconOnly, ButtonIconRound, ButtonIconWithBorder } from 'src/components/buttons/icon-button';
import CommaSeparatedList from 'src/components/common/comma-separated-list';
import ContainerGrid from 'src/components/container-grid';
import ControlsVolume from 'src/components/controls-volume';
import { LiveConcertBadge } from 'src/components/live-concert/live-concert-badge';
import PerformanceWorkList from 'src/components/performance-work/performance-work-list';
import { useSitePlayerContext } from 'src/components/site-player';
import SitePlayerControlsPlayback from 'src/components/site-player-controls-playback';
import { SitePlayerProgress } from 'src/components/site-player-progress';
import { SitePlayerSettingsMenu } from 'src/components/site-player-settings';
import useClickOutside from 'src/hooks/use-click-outside';
import { useFavorite } from 'src/hooks/use-favorite';
import usePictureInPicture from 'src/hooks/use-picture-in-picture';
import useRemotePlayback from 'src/hooks/use-remote-playback';
import useTranslate from 'src/hooks/use-translate';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { FavoritableNode } from 'src/utilities/favorite-helpers';
import { AudioStreamQuality } from 'src/utilities/streaming-helpers';
import { getNodePath } from 'src/utilities/url-helpers';

/**
 * Display some info about the current audio stream, e.g. the quality level of the stream.
 * @returns
 */
const AudioStreamInfo = () => {
  const t = useTranslate();
  // audio tracks of the stream
  const { audio } = useVideoContext();
  // one of the possible audio channel names: 'sd', 'hd, 'stereogood', 'stereohigh', 'atmos', 'lossless'
  const audioTrackName = audio?.currentAudioTrack?.label as AudioStreamQuality;
  // only the social audio channel names are displayed, e.g. atmos, lossless
  const shouldBeDisplayed = audioTrackName === 'atmos' || audioTrackName === 'lossless';
  // no named audio track, nothing to display
  if (!shouldBeDisplayed) {
    return null;
  }
  return (
    <div className="pointer-events-none relative z-10 text-surface-200" data-test="audio-stream-info">
      {audioTrackName === 'atmos' && <AudioIndicatorAtmos aria-label={t('mediaQuality__title_audio_atmos')} />}
      {audioTrackName === 'lossless' && <AudioIndicatorLossless aria-label={t('mediaQuality__title_audio_lossless')} />}
    </div>
  );
};

function SitePlayerFavoriteButton({ node }: { node: FavoritableNode }) {
  const { isFavorite, toggleFavorite } = useFavorite(node);
  return <FavoriteButton isActive={isFavorite} onClick={toggleFavorite} />;
}

/**
 * Display the title related of the current video or audio track
 */
const CurrentPlayerItemTitle = () => {
  const t = useTranslate();
  // get the hook to the playback state
  const { currentActiveWork, currentActiveTrack, currentMedia } = usePlayback();

  const isTrailer = currentMedia?.__typename === 'Trailer';
  const title =
    // prepend "Trailer - " when the current media is a trailer
    (isTrailer && `${t('component__video_player_trailer_label')} - ${currentMedia?.title}`) ||
    // otherwise just display the most relevant title of the current media
    currentActiveTrack?.title ||
    currentActiveWork?.work.title ||
    currentMedia?.title;
  return <span>{title}</span>;
};

export const SitePlayerChaptersToggleMobile = () => {
  const t = useTranslate();
  // get the hook to the track list visible state
  const { setTrackListVisible } = useSitePlayerContext();
  // get the hook to the playback state
  const { nextItem, currentWorks, currentTracks } = usePlayback();
  const hasChapters = !!(currentTracks || currentWorks);
  // retrieve the title of the next performance work or track
  const nextItemTitle =
    nextItem && (('title' in nextItem && nextItem.title) || ('work' in nextItem && nextItem.work.title));
  /** a special playlist toggle on mobile screens */
  if (!hasChapters) {
    return null;
  }

  return (
    <button
      data-test="chapters-overlay-toggle-mobile"
      className="pointer-events-auto absolute inset-x-0 bottom-0 col-span-full h-20 w-full border-t border-white/25 md:hidden lg:z-20 landscape:hidden"
      onClick={() => setTrackListVisible(true)}
      aria-label={t('player__button_list')}
    >
      <div className="ml-8 mr-6 flex items-center space-x-2">
        <div className="dg-text-regular-6 flex grow flex-col pb-6 pt-4 text-left text-white">
          {nextItemTitle && (
            <Fragment>
              <div className="opacity-35">{t('player__next_work')}</div>
              <div className="line-clamp-1">{nextItemTitle}</div>
            </Fragment>
          )}
        </div>
        <div className="h-full">
          <WorklistIcon aria-hidden className="size-6" />
        </div>
      </div>
    </button>
  );
};

/**
 * Display the additional title related to the current video or audio track
 */
const CurrentPlayerItemInfo = () => {
  // get the hook to the playback state
  const { currentActiveWork, currentActiveTrack } = usePlayback();

  if (currentActiveWork?.work.composers) {
    return (
      <CommaSeparatedList
        list={currentActiveWork?.work.composers}
        renderItem={(composer) => <span key={composer.id}>{composer.name}</span>}
      />
    );
  }

  if (currentActiveTrack?.artistAndGroupDisplayInfo) {
    return <span>{currentActiveTrack?.artistAndGroupDisplayInfo}</span>;
  }

  return null;
};

/** The list of performance works or tracks displayed in the player */
export const SitePlayerChaptersList = () => {
  const t = useTranslate();
  // get the hook to the tracklist visible state
  const { trackListVisible, setTrackListVisible } = useSitePlayerContext();
  // get the hook to the playback state
  const { currentMedia } = usePlayback();
  const trackSets = currentMedia?.__typename === 'Album' && currentMedia?.trackSets;
  const performanceWorks = currentMedia?.__typename === 'VodConcert' && currentMedia?.performanceWorks;
  const chaptersRef = useRef<HTMLDivElement>(null);
  // close the overlay on a click outside
  useClickOutside({ ref: chaptersRef, callback: () => setTrackListVisible(false), isActive: trackListVisible });

  // close the overlay when user selects a track or performance work
  const chapterSelectionHandler = (event: SyntheticEvent) => {
    // check if the event was triggered by a click on a link or a descendant of a link
    if (event.target instanceof HTMLElement && event.target.closest('a')) {
      setTrackListVisible(false);
    }
  };

  return (
    <Transition
      show={trackListVisible}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        ref={chaptersRef}
        className="pointer-events-auto fixed inset-y-0 right-0 z-50 w-full overflow-auto bg-mainBgBlueC2 sm:w-[375px] lg:w-[400px] 2xl:w-[420px]"
        data-test="chapters-overlay"
      >
        <div className="flex items-center justify-between pb-3 pl-4 pr-5 pt-5">
          <div className="dg-text-regular-4 text-white opacity-70">{t('player__works_title')}</div>
          <ButtonIconWithBorder
            title={t('player__works_close')}
            icon={<CloseIcon />}
            data-test="modal-close"
            className="outline-none"
            onClick={() => setTrackListVisible(false)}
          />
        </div>
        <div onClick={chapterSelectionHandler}>
          {performanceWorks && <PerformanceWorkList performanceWorks={performanceWorks} video={currentMedia} />}
          {trackSets && <AudioList trackSets={trackSets} album={currentMedia} />}
        </div>
      </div>
    </Transition>
  );
};

/**
 * The controls buttons of the maxi video player
 * @component
 */
export const SitePlayerControls = () => {
  const t = useTranslate();

  // get the hook to the playback state
  const { currentMedia, currentActiveWork, currentActiveTrack, currentWorks, currentTracks } = usePlayback();

  // the site player state
  const { setTrackListVisible } = useSitePlayerContext();
  const isLiveConcert = currentMedia?.__typename === 'LiveConcert';
  const isAlbum = currentMedia?.__typename === 'Album';
  const hasChapters = !!(currentTracks || currentWorks);
  // users can't change current playback position in live concert
  const canDisplayProgressTimeline = !isLiveConcert;
  // find out the track or work index in the queue
  const queueIndex =
    (currentActiveTrack && currentTracks?.indexOf(currentActiveTrack)) ||
    (currentActiveWork && currentWorks?.indexOf(currentActiveWork)) ||
    0;

  return (
    <ContainerGrid>
      <div className="col-span-6 mb-6 md:col-span-full" data-test="site-player-controls">
        <div className="mb-9 text-center md:mb-6 md:text-left landscape:text-left">
          {currentMedia?.__typename === 'LiveConcert' && <LiveConcertBadge liveConcert={currentMedia} />}
          <div className="dg-text-regular-4 mb-1 text-white opacity-70">
            <CurrentPlayerItemInfo />
          </div>
          <h3 className="dg-text-medium-3 mb-2 md:line-clamp-1">
            <CurrentPlayerItemTitle />
          </h3>
          <div className="dg-text-regular-4 text-white opacity-70">
            {queueIndex >= 0 && currentWorks?.length
              ? t('player__work_number_subtitle', [queueIndex + 1, currentWorks.length])
              : ''}
            {queueIndex >= 0 && currentTracks?.length
              ? t('player__track_number_subtitle', [queueIndex + 1, currentTracks.length])
              : ''}

            {isLiveConcert && currentMedia.subtitle && <span>{currentMedia.subtitle}</span>}
          </div>
        </div>
        {canDisplayProgressTimeline && <SitePlayerProgress />}
        <div className="mt-4 flex flex-col items-center md:mt-6">
          <div className="absolute left-0 flex items-center justify-center space-x-4">
            <ControlsVolume />
            <div className="hidden md:flex">
              <AudioStreamInfo />
            </div>
          </div>

          <div className="flex grow items-center justify-center space-x-4">
            <SitePlayerControlsPlayback />
          </div>
          {/* Display audio quality on smaller screens below the controls */}
          <div className="mt-2 flex w-full items-center justify-center md:hidden">
            <AudioStreamInfo />
          </div>
          <div className="absolute right-0 flex space-x-2">
            {currentActiveWork && <SitePlayerFavoriteButton node={currentActiveWork} />}
            {isAlbum && currentMedia && <SitePlayerFavoriteButton node={currentMedia} />}
            {hasChapters && (
              <ButtonIconRound
                title={t('player__button_list')}
                icon={<WorklistIcon aria-hidden />}
                dataTest="chapters-toggle"
                onClick={() => setTrackListVisible(true)}
                className="hidden size-8 md:flex"
              />
            )}
          </div>
        </div>
      </div>
    </ContainerGrid>
  );
};

/**
 * The controls buttons of the mini video player
 * @component
 */
export const SitePlayerControlsMini = () => {
  const t = useTranslate();
  const { isPlaying, pause, play } = useVideoContext();

  const { currentMedia } = usePlayback();

  const playPauseHandler = (event: SyntheticEvent) => {
    // usually a click on the mini player opens the maxi player
    // except when a user clicks on a play/pause button
    // so in this case we are making sure
    // that the mini player container doesn't receive this click
    event.stopPropagation();
    // toggle the play/pause state
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  return (
    <div data-test="site-player-controls-mini" className="flex w-full items-center">
      <div className="grow">
        <div className="dg-text-regular-4 line-clamp-1 text-white opacity-70">{currentMedia?.title}</div>
        <div className="dg-text-medium-8 line-clamp-1">
          <CurrentPlayerItemTitle />
        </div>
      </div>
      <div className="mr-6 h-8">
        <ButtonIconRound
          title={t(isPlaying ? 'player__button_pause' : 'player__button_play')}
          icon={isPlaying ? <PauseIcon /> : <PlayIcon />}
          onClick={playPauseHandler}
        />
      </div>
    </div>
  );
};

type SitePlayerControlsOptionsProps = {
  isFullscreen: boolean;
  onMini: () => void;
  onFullScreenOn: () => void;
  onFullScreenOff: () => void;
};

/**
 * The options buttons of the maxi video player
 * @component
 */
export const SitePlayerControlsOptions = ({
  isFullscreen,
  onMini,
  onFullScreenOn,
  onFullScreenOff,
}: SitePlayerControlsOptionsProps) => {
  const t = useTranslate();
  // get the current video/audio stream
  const { currentMedia } = usePlayback();
  const isVideo = currentMedia?.__typename !== 'Album';

  const { videoRef, textTracks } = useVideoContext();

  // set up the remote playback button
  const { remotePlaybackAvailable, remotePlaybackActive, showRemotePlaybackPicker } = useRemotePlayback({ videoRef });

  // set up picture-in-picture button
  const { isPictureInPictureAvailable, isPictureInPictureActive, setPictureInPicture } = usePictureInPicture({
    videoRef,
  });
  // the link back to the detail page of the currently playing media
  const detailPageUrl = currentMedia && getNodePath(currentMedia);

  return (
    <div data-test="site-player-controls-options" className="flex flex-wrap justify-between md:flex-nowrap">
      <div className="w-1/4 sm:portrait:w-1/2">
        {detailPageUrl && (
          <Link
            href={detailPageUrl}
            onClick={onMini}
            data-test="minimize-player-button"
            className="group inline-flex h-6 items-center rounded-full bg-highlightBlueC3/0 pl-1 pr-5 transition-all duration-200 hover:bg-highlightBlueC3/100 md:h-8"
          >
            <ChevronDownIcon className="group-hover:text-brandYellowC1" />
            <span className="dg-text-regular-4 text-white text-opacity-0 transition-all group-hover:translate-x-1 group-hover:text-opacity-70">
              {t('player__button_minimize')}
            </span>
          </Link>
        )}
      </div>
      <div className="dg-text-medium-8 order-last mt-8 h-8 w-full text-center md:order-none md:mt-0 landscape:order-none landscape:mt-2 landscape:w-auto">
        {currentMedia?.title}
      </div>
      <div className="flex w-1/4 justify-end space-x-4">
        <Menu as="div" className="relative size-6">
          <MenuButton
            as={ButtonIconOnly}
            title={t('player__button_settings')}
            icon={<SettingsIcon aria-hidden />}
            className="size-6"
            data-test="site-player-settings"
          />
          <MenuItems
            className={clsx(
              // the settings menu fills the whole screen on mobile and is aligned to the button on larger screens
              'fixed inset-x-7 top-20 origin-top-right md:absolute md:left-auto md:right-1 md:top-10 md:w-[320px]',
              'overflow-hidden rounded-xl bg-textWhite py-1 shadow-lg transition-transform duration-200 ease-in-out focus:outline-none',
            )}
          >
            <SitePlayerSettingsMenu textTracks={textTracks} />
          </MenuItems>
        </Menu>

        {remotePlaybackAvailable && (
          <ButtonIconOnly
            title={t('player__button_airplay')}
            icon={<AirplayIcon />}
            onClick={showRemotePlaybackPicker}
            className="size-6"
            data-test="site-player-remote-playback"
            active={remotePlaybackActive}
          />
        )}
        {isVideo && isPictureInPictureAvailable && (
          <ButtonIconOnly
            title={t('player__button_pip')}
            icon={<PictureInPictureIcon />}
            onClick={() => setPictureInPicture(!isPictureInPictureActive)}
            className="size-6"
            data-test="site-player-remote-pip"
            active={isPictureInPictureActive}
          />
        )}
        {isVideo &&
          (isFullscreen ? (
            <ButtonIconOnly
              title={t('player__button_fullscreen_exit')}
              icon={<FullscreenExitIcon />}
              onClick={onFullScreenOff}
              className="size-6"
              data-test="site-player-fullscreen-exit"
            />
          ) : (
            <ButtonIconOnly
              title={t('player__button_fullscreen_enter')}
              icon={<FullscreenEnterIcon />}
              onClick={onFullScreenOn}
              className="size-6"
              data-test="site-player-fullscreen-enter"
            />
          ))}
      </div>
    </div>
  );
};
