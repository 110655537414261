import { RefObject, useEffect, useState } from 'react';
import { isInAppBrowser } from 'src/utilities/capabilities';
import { captureException } from 'src/utilities/exceptions';

/**
 * Detects if Picture in Picture mode is available and allows toggling it
 * @hook
 * @param videoRef the reference to the video element
 * @param onEnter called when the video enters Picture in Picture mode
 * @param onLeave called when the video leaves Picture in Picture mode
 * @returns an object with following fields -
 *  `isPictureInPictureAvailable`, `isPictureInPictureActive` booleans and `setPictureInPicture` method for activating the picture in picture mode
 */
const usePictureInPicture = ({
  videoRef,
  onEnter,
  onLeave,
}: {
  videoRef: RefObject<HTMLVideoElement>;
  onEnter?: () => void;
  onLeave?: () => void;
}) => {
  // track fullscreen DOM state locally
  const [isPictureInPictureActive, setIsPictureInPictureActive] = useState(false);

  // check if the video is loaded and can be played
  // otherwise, the PictureInPicture action will trigger a browser error "Failed to execute 'requestPictureInPicture' on 'HTMLVideoElement': The element has no supported sources."
  const videoLoaded = videoRef.current && videoRef.current?.readyState >= 3;

  // check PiP support
  const isPictureInPictureApiAvailable =
    !isInAppBrowser && // most in-app browsers don't support it
    'pictureInPictureEnabled' in document && // check if PictureInPicture API is available
    !videoRef.current?.getAttribute('disablePictureInPicture'); // check if the video element doesn’t have PictureInPicture disabled

  // check if PictureInPicture API is available and video is loaded, then the PictureInPicture mode can be toggled
  const isPictureInPictureAvailable = isPictureInPictureApiAvailable && videoLoaded;

  const setPictureInPicture = (on: boolean) => {
    const video = videoRef.current;
    if (!video || !isPictureInPictureApiAvailable) {
      return;
    }
    if (on) {
      void video.requestPictureInPicture().catch(captureException);
    } else {
      void document.exitPictureInPicture().catch(captureException);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !isPictureInPictureApiAvailable) {
      return;
    }
    const PictureInPictureEnterHandler = () => {
      setIsPictureInPictureActive(true);
      onEnter?.();
    };
    const PictureInPictureLeaveHandler = () => {
      setIsPictureInPictureActive(false);
      onLeave?.();
    };
    const videoEndHandler = () => {
      if (document.pictureInPictureElement) {
        void document.exitPictureInPicture().catch(captureException);
      }
    };
    // detect picture in picture mode changes
    video.addEventListener('enterpictureinpicture', PictureInPictureEnterHandler);
    video.addEventListener('leavepictureinpicture', PictureInPictureLeaveHandler);
    // detect when a video ends and exit Picture in Picture mode,
    // otherwise there will be an empty Picture in Picture screen
    // while the video has switched to the embedded player
    video.addEventListener('ended', videoEndHandler);
    // close the Picture in Picture mode when the video is unloaded eg. when the player is closed
    // note that this listener should not be removed as it's a cleanup for the video element
    video.addEventListener('emptied', videoEndHandler);

    return () => {
      video?.removeEventListener('enterpictureinpicture', PictureInPictureEnterHandler);
      video?.removeEventListener('leavepictureinpicture', PictureInPictureLeaveHandler);
      video?.removeEventListener('ended', videoEndHandler);
    };
  }, [videoRef, isPictureInPictureApiAvailable, onEnter, onLeave]);

  return { isPictureInPictureAvailable, isPictureInPictureActive, setPictureInPicture };
};

export default usePictureInPicture;
